/*
 * @Author: your name
 * @Date: 2021-01-28 15:27:27
 * @LastEditTime: 2025-01-07 14:30:00
 * @LastEditors: hl
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/assets/languages/zh.js
 */

import AxgConfig from "../../views/download/store/axg/config";
import AxtConfig from "../../views/download/store/axt/config";

/*
 * @Author: your name
 * @Date: 2021-01-28 15:27:27
 * @LastEditTime: 2021-01-28 19:28:13
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/assets/languages/zh.js
 */
let obj = {
  languages: "中文",
};

// 一些常规名字
obj.toKnowMore = "了解更多";
obj.nameInputPhone = "请正确输入手机号";
obj.namePhone = "请输入手机号";
obj.nameInputPassword = "请输入密码（6-12位数字或字母）";
obj.namePassword = "请输入密码";
obj.nameInputPasswordAgin = "请再次输入密码（6-12位数字或字母）";
obj.nameInputPasswordNotSame = "两次输入密码不相同";
obj.nameInputPasswordNotAllow = "密码应是（6-12位数字或字母）";
obj.nameInputYzm = "请输入验证码";
obj.nameYzm = "验证码";
obj.nameLijizhuce = "立即注册";
obj.nameClickzhuce = "点击注册";
obj.nameBackHome = "返回首页";
obj.namewaitReview = "请等待人工审核";
obj.nameSubSucess = "提交成功";
obj.nameConfirmChange = "确认修改";
obj.nameChangeSucess = "修改成功";
obj.getCode = "获取验证码";
obj.getCodeOnece = "重新获取";
obj.daojishi = "秒后可重新获取";
obj.canNotSubmit = "";
obj.nameInput = "请输入";
//首页顶部菜单列表
obj.barList = [
  {
    name: "公司介绍",
    id: "1",
  },
  {
    name: "公司业务",
    id: "2",
  },
  {
    name: "供应链合作",
    id: "3",
  },
  {
    name: "达人入驻",
    id: "4",
  },
  {
    name: "公司资讯",
    id: "5",
  },
  {
    name: "合作品牌",
    id: "6",
  },
];
// 首页顶部登录信息
obj.TopLoginData = [
  {
    name: "消息通知",
    url: "/noticeMsg",
  },
  {
    name: "修改密码",
    url: "/changePassword",
  },
  {
    name: "退出登录",
    url: "",
  },
];

// 首页顶部swiper滑块数据
let timeDate = new Date();
// let year = timeDate.getYear(); //获取当前年份(2位)
// let m = timeDate.getMonth() + 1;
// m = m < 10 ? "0" + m : m;
let day = timeDate.getDate();

let swperData = {};

//至3.21号的swiper数据(PC)
swperData.swper_end210321 = [
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end210321/4-pc-new.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: "azabord?prev=outline"
  // },
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end210321/1-pc.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: ""
  // },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/1_2023_11_20.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "https://www.myaz.com/applyIntr",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/1_2024_08_23.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "https://www.myaz.com/azabord?prev=outline",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/3_2024_08_23.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/5_2023_07_19.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/4_2023_07_19.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end220422/2_2023_07_19.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: ""
  // },
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end220422/3.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: "",
  // },
];

//至3.21号的swiper数据(WAP)
swperData.swper_end210321wap = [
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end210321/4-mob-new.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: "azabord?prev=outline"
  // },
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end210321/1-mob.png") +
  //     `?t=${new Date().getTime()}`,
  //   goto: ""
  // },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/11_2023_11_20.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "https://www.myaz.com/applyIntr",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/11_2024_08_23.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "https://www.myaz.com/azabord?prev=outline",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/33_2024_08_23.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/55_2023_07_19.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/44_2023_07_19.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end220422/33.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: "",
  // },
];
console.log(day);
// if (day < 15) {
//   obj.TopMenuBarData = swperData.swper_end210314;
//   obj.WapTopMenuBarData = swperData.swper_end210314wap;
// } else if (day < 22) {
//   obj.TopMenuBarData = swperData.swper_end210321;
//   obj.WapTopMenuBarData = swperData.swper_end210321wap;
// } else {
//   obj.TopMenuBarData = swperData.swper_end210314;
//   obj.WapTopMenuBarData = swperData.swper_end210314wap;
// }

obj.TopMenuBarData = swperData.swper_end210321;
obj.WapTopMenuBarData = swperData.swper_end210321wap;

// 首页公司介绍
obj.PcGroupIntroductionData = {
  leftImg: require("@/assets/img/shouye/Mask Group@2x.png"),
  content: {
    title: "公司介绍",
    details: `azglobal公司2015年于澳洲成立，目前已与香港联合公司李明治先生达成战略合作，同时战略投资了澳洲上市公司Wiseway并为其单一最大股东，战略持股澳洲上市公司Bubs羊奶粉品牌并 为其前15大股东。
    <br>az global公司是澳新多个品牌对华最大的出口公司，拥有多个品牌的独家 出口权，与众多知名药房、品牌合作，在全澳5大城市持有6个大型仓库。凭借其销售渠道体系多个品牌成为了中澳家喻户晓的品牌。`,
  },
};
// wap首页公司介绍
obj.WapIndexIntr = {
  title: "公司介绍",
  details: "azg公司立足澳新，联动国内，走向世界",
  more: "了解更多",
};
// wap首页公司业务
obj.WapIndexIntr1 = {
  title: "公司业务",
  details: "azg公司相信，再微小的力量",
  details1: "汇集在一起，也有无限可能",
  pictures: [
    {
      img: require("@/assets/img/index/x1_2023_01_19@2x.png"),
      name: "online",
    },
    {
      img: require("@/assets/img/index/x22.jpg"),
      name: "outline",
    },
    {
      img: require("@/assets/img/index/x3.jpg"),
      name: "az",
    },
  ],
  wappictures: [
    {
      img: require("@/assets/img/index/Group 6852@2x.png"),
      name: "online",
    },
    {
      img: require("@/assets/img/index/Group 6853@2x.png"),
      name: "outline",
    },
    {
      img: require("@/assets/img/index/Group 6854@2x.png"),
      name: "az",
    },
  ],
};
// wap首页供应链合作
obj.WapIndexIntr2 = {
  title: "供应链合作",
  details: "azg公司寻求全球品质好货",
  details1: "分享品位生活，实现全球好货通全球",
  more: "联系我们",
};
// wap首页达人入驻
obj.WapIndexIntr3 = {
  title: "达人入驻",
  details: "官方合作推品官，分享全球好货，为品质代言",
  more: "联系我们",
  pic: [
    require("@/assets/img/index/Group 6848@2x.png"),
    require("@/assets/img/index/Group 6849@2x.png"),
    require("@/assets/img/index/Group 6850@2x.png"),
  ],
  webPic: [
    require("@/assets/img/index/Rectangle 1451@2x.png"),
    require("@/assets/img/index/Rectangle 1481@2x.png"),
    require("@/assets/img/index/Rectangle 147@2x.jpg"),
  ],
};
// wap首页公司资讯
obj.WapIndexIntr4 = {
  title: "公司资讯",
  pic: [
    require("@/assets/img/shouye/Group 124@2x.png"),
    require("@/assets/img/shouye/Group 125@2x.png"),
    require("@/assets/img/shouye/Group 126@2x.png"),
    require("@/assets/img/shouye/Group 127@2x.png"),
  ],
};
// wap首页合作品牌
obj.WapIndexIntr5 = {
  title: "合作品牌",
  details: "公司在澳新产业带、日本产业带、欧洲产业带深入合作",
  details1: "在美妆个护、食品、保健品、数码家居等领域深度开发合作。",
  pic: `https://assets.img.myazstore.com/azg-website/partnerships_wap.png?t=${new Date().getTime()}`,
};
// wap首页底部信息
obj.WapBottom = {
  address: {
    title: "公司地址：",
    add110: "中国总部:",
    add1: "中国杭州市滨江区",
    add11: "建业路599号华业发展中心3301-3305室",
    add2: "香港总部:",
    add22: "香港湾仔告士打道138号联合鹿岛大厦",
    add3: "",
    add33: "",
  },
  relation: {
    title: "联系方式：",
    emal: "邮箱：info@alpha-hk.com",
  },
  bottom: {
    bot1: "ICP备案: 辽ICP备2022005380号",
    bot2: "经营许可证编号: 辽B2-20210246",
    bot3: "沈阳金蚁电子商务有限公司", //2022-08-16 时雨添加
  },
  liaoAddress: {
    title: "公司地址：",
    add110: "中国总部:",
    add1: "辽宁省沈阳市浑南区",
    add11: "营盘北街7-3号（1511）",
    add2: "Melbourne Headquarters:",
    add22: "2 Southpark Close Keysborough 3173",
    add3: "Sydney Headquarters:",
    add33: "152 Milperra Rd, Revesby NSW, 2212",
  },
  liaoRelation: {
    title: "联系方式：",
    emal: "邮箱：info@alpha-hk.com",
  },
  liaoBottom: {
    bot1: "ICP备案: 辽ICP备2021004242号-2",
    bot2: "网络文化经营许可证: 辽网文（2021）2008-042号",
  },
};
// 公司介绍信息
obj.WapCompanyIntr = {
  title: "公司简介",
  det1:
    "azglobal公司于2015年在澳洲成立，目前已与香港联合公司李明治先生达成战略合作，是澳新品牌对华最大的出口公司之一，拥有多个品牌的独家出口权，与澳洲本土药房、品牌达成深度合作，在全澳5大城市持有6个大型仓库，总面积超30000㎡。我们立足于澳新，深耕国内市场，并积极布局全球产业链模式，和多个国际品牌展开深度战略合作，实现全球买全球卖。",
  det2:
    "公司在澳大利亚、新西兰、中国香港、中国澳门、中国杭州均有设立分公司和团队，秉承忠诚、正直、稳定的文化价值观，组建独立的销售、运营、IT产技研发、市场等专业团队， 搭建并支持了超5万个中小渠道的销售体系。",
  det3:
    "公司多年来专注于品牌源头的深度合作，已经和Bubs、Bellamy's贝拉米、CapriLac、Capela等知名品牌展开战略合作，同时拥有多个自有品牌，如三川更、朔野集、果以时等。我们关注80%的小渠道，重点于小渠道小商户的布局和建设，帮助和扶持中小企业，为大多数民众提供更多的就业机会，按需分配品牌货源，多劳多得，让80%的小渠道赚到80%的钱。",
  det4:
    "azg自主研发的az全球购、az好货通、澳洲中央仓等IT系统，把公司产业链进行数字化管理，覆盖销售、市场、物流等全体系，提高品牌、销售渠道的内部效率，增加产业壁垒，从而增强品牌竞争力。我们根据渠道数据，科学预测渠道出货能力，搭配azg全国物流仓储体系，把商品配送到最后一公里，杜绝串货、假货及乱价行为，维护品牌稳定性及生命力，并利用科技手段实现线上线下一体化，激发销售体系的营销积极性，实现品牌维护好渠道，渠道服务好用户，用户忠诚于品牌的三方共振，三方共赢的局面。",
};
// web az海外
obj.AaAbordTop = {
  azTitle: "欢迎您加入az海外",
  azDet:
    "az海外依托azg公司自有海外仓库和代发货系统，串联海外华人代购、线下商超和代购自营店，集批发，代发，海外仓，云仓，直播基地以及品牌推广等业务于一体。az海外为海外代购提供免费仓储、代发物流、代打包代发货、直播等业务，最大程度节省采购和仓储成本，无囤货风险，且可以通过az中国自主研发的“az全球购”APP，轻松开店销售全球精选商品，合理合法阳光化；为海外代购自营线下店及海外商超提供丰富的品质货源，提供仓储、物流及代发货等服务，并借助az线上系统，进行门店二维码广告投放。用户在线下店购物的同时，可以通过扫描二维码一键下单，为国内亲朋好友快速送上海外好货，az仓储系统实现全国配送。",
  azBtn1: "入驻az澳新购",
  azBtn2: "入驻az澳新通",
  azapplyQueryBtn1: "/applyOversea?type=1",
  azapplyQueryBtn2: "/applyOversea?type=2",
  onlineTitle: "欢迎您加入az线上中国",
  onlineDet:
    "以全球好货，优质服务，简单生活为核心理念，精选全球优质供应链资源，借助azg公司自主研发的APP工具“az全球购”，整合优质的物流体系，打造azg品控体系，通过内容多元化营销、个性化运营培训、无忧售后等多方面的一体化服务，全面赋能中小渠道卖家通过优质服务体验触达终端消费者，打造优质、快速、高效的零售新体验。",
  onlineBtn1: "个人入驻",
  onlineapplyQueryBtn1: "/applyAgent?whoApply=personalApply",
  onlineBtn2: "企业入驻",
  onlineapplyQueryBtn2: "/applyAgent?whoApply=companyApply",
  outlineTitle: "欢迎您加入az线下中国",
  outlineDet:
    "依托azg公司全球品质供应链资源和仓储物流体系，结合azg核心品类和核心品牌优势，整合品牌市场营销资源，通过省代/市代加盟的模式，打通全国线下中小渠道，并借助azg公司自主研发的APP工具“az好货通”，把全球好货铺到全国线下小店，实现“派送到最后一公里”的目标，小店无需囤货、压货。赋能中、小、微小商户、帮助万千中小商户更好地服务终端消费者，打造优质、便捷、高效的零售新体验。",
  outlineBtn1: "省级代理入驻",
  outlineapplyQueryBtn1: "/applyAgent?whoApply=provinceApply",
  outlineBtn2: "地级代理入驻",
  outlineapplyQueryBtn2: "/applyAgent?whoApply=cityApply",
  steps: [
    {
      title: "注册登入",
      det: "用户az平台选择注册登入",
    },
    {
      title: "选择店铺",
      det: "选择店铺类型",
    },
    {
      title: "提交材料",
      det: "填写并上传相关信息",
    },
    {
      title: "审核资质",
      det: "资质审核将于，3个工作日完成",
    },
  ],
  azgoodness: [
    {
      // img: require("@/assets/img/az/1_1@2x.png"),
      img: require("@/assets/img/az/1_4@2x.png"),
      title: "一站式仓储物流",
      det: "az自有仓储体系，集仓库、办公、直播功能、专业代打包发货为一体",
    },
    {
      img: require("@/assets/img/az/1_2@2x.png"),
      title: "支付便捷",
      det: "精细化成本核算，费用一目了然，多种付款方式",
    },
    {
      // img: require("@/assets/img/az/1_3@2x.png"),
      img: require("@/assets/img/az/earth.png"),
      title: "优质供应链",
      det: "澳新和中国优质供应链体系",
    },
    // {
    //   img: require("@/assets/img/az/1_4@2x.png"),
    //   title: "发货多样",
    //   det: "一键下单，专业化打包，极速发货，支持国内保税仓发货"
    // },
    {
      // img: require("@/assets/img/az/1_5@2x.png"),
      img: require("@/assets/img/az/1_3@2x.png"),
      title: "营销支持",
      det: "品牌活动、线上直播、门店活动策划培训等",
    },
  ],
  onlinegoodness: [
    {
      img: require("@/assets/img/az/1_111@2x.png"),
      title: "品质货源",
      det: "优质国内外货源，正品保证，品类齐全，品质保障",
    },
    {
      img: require("@/assets/img/az/1_222@2x.png"),
      title: "一键开店",
      det: "工具化APP，零成本一键开店，便捷选货上货，个性化店铺",
    },
    {
      img: require("@/assets/img/az/1_333@2x.png"),
      title: "azg发货",
      det: "azg中央仓直接发货，无囤货风险",
    },
    {
      img: require("@/assets/img/az/1_444@2x.png"),
      title: "业务支持",
      det: "授课培训、快速上手、海量素材、无忧售后",
    },
  ],
  outlinegoodness: [
    {
      img: require("@/assets/img/az/1_11@2x.png"),
      title: "az综合销售服务体系",
      det:
        "az自建专业的线下销售赋能培训团队，为省代/市代提供全方位的营销培训、产品知识宣导、系统培训、市场体系维护等服务，充分激发和支持各区域销售积极性。",
    },
    {
      img: require("@/assets/img/az/1_22@2x.png"),
      title: "品类结构升级",
      det:
        "小店享受az独家品牌、核心品牌和自有品牌的品质货源体系， 更可以通过二维码广告展示的方式在线销售跨境商品，实现线上线下打通，全球品质货源打通。",
    },
    {
      img: require("@/assets/img/az/1_33@2x.png"),
      title: "灵活的营销方式",
      det:
        "省代/市代在遵循az销售体系规则和公平竞争原则的同时，可以充分发挥自身的营销优势，在各自区域内联动az和品牌方实现个性化、多样化的营销方式，促活市场，推动销售业绩的提升。",
    },
  ],
  onlinedownload: {
    // Group 6831@2x
    bgImg: require("@/assets/img/az/online_bg_2023_01_19_2.png"),
    title1: "az全球",
    title2: "购",
    // det:
    //   "az全球购是azg公司自主研发的跨境+一般贸易电商平台，以“甄选品质好货，分享品位生活”为核心理念，精选全球优质供应链，通过无忧的售后支持、完整的仓储物流体系、赋能线上中小渠道，通过线上社交分享模式，为用户提供好品牌、好货品、好服务，全程合理合法阳光化。",
    det:
      "az全球购是azg公司自主研发的跨境+一般贸易的工具系统。以“全球好货，优质服务，简单生活”为核心理念，精选全球优质供应链，通过无忧的售后支持、完整的仓储物流体系、赋能线上中小渠道，为用户提供好品牌、好货品、好服务，全程合理合法阳光化。",
    logoImg: require("@/assets/img/az/Group 106@2x.png"),
    iosImg: require("@/assets/img/az/ios_online_eq.png"),
    iosTitle: "ios下载",
    iosLogo: require("@/assets/img/az/ios_1@2x.png"),
    // androidImg: require("@/assets/img/az/az_online.png"),
    androidImg: require("@/assets/img/az/quanqiugou_android_cdn.png"),
    androidTitle: "安卓下载",
    androidLogo: require("@/assets/img/az/android_1@2x.png"),
  },
  outlinedownload: {
    bgImg: require("@/assets/img/az/outline_bg_2023_01_19.png"),
    title1: "az好货",
    title2: "通",
    det:
      "az好货通是由azg公司自主研发的、为线下经销商及中小渠道赋能的智能IT系统。通过丰富的全球品质好货和独家品牌体系，稳定的价值链，智能的营销工具和数据分析，帮助传统渠道丰富品类结构，提升小店经营效率，帮助品牌渗透到更多的中小，微小渠道。",
    logoImg: require("@/assets/img/az/Group 105@2x.png"),
    // logoImg: require("@/assets/img/az/outline_new_android.png"),
    iosImg: require("@/assets/img/az/and_online_eq.png"),
    iosTitle: "ios下载",
    iosLogo: require("@/assets/img/az/ios_1@2x.png"),
    androidImg: require("@/assets/img/az/outline_new_android_2023_02_23.jpg"),
    androidTitle: "安卓下载",
    androidLogo: require("@/assets/img/az/android_1@2x.png"),
  },
  waponlinedownload: {
    // title1: "az全球",
    // title2: "购",
    logologo: require("@/assets/img/az/a_logo@2x.png"),
    logofont: require("@/assets/img/az/azlogo-01 3@2x.png"),
    det:
      "az全球购是azg公司自主研发的跨境+一般贸易电商平台，以“全球好货，优质服务，简单生活”为核心理念，精选全球优质供应链，通过无忧的售后支持、完整的仓储物流体系、赋能线上中小渠道，通过线上社交分享模式，为用户提供好品牌、好货品、好服务，全程合理合法阳光化。",
    logoImg: require("@/assets/img/az/Group 106@2x.png"),
    // iosImg: require("@/assets/img/az/ios_online_eq.png"),
    // iosTitle: "ios下载",
    // androidImg: require("@/assets/img/az/az_online.png"),
    // androidTitle: "安卓下载",
    // wap_phone@2x
    phonelogo: require("@/assets/img/az/online_bg_wap_2023_01_19_2.png"),
  },
  wapoutlinedownload: {
    // title1: "az全球",
    // title2: "购",
    logologo: require("@/assets/img/az/wap_logo@2x.png"),
    logofont: require("@/assets/img/az/wap_font@2x.png"),
    det:
      "az全球购是azg公司自主研发的跨境+一般贸易电商平台，以“全球好货，优质服务，简单生活”为核心理念，精选全球优质供应链，通过无忧的售后支持、完整的仓储物流体系、赋能线上中小渠道，通过线上社交分享模式，为用户提供好品牌、好货品、好服务，全程合理合法阳光化。",
    logoImg: require("@/assets/img/az/Group 106@2x.png"),
    // iosImg: require("@/assets/img/az/ios_online_eq.png"),
    // iosTitle: "ios下载",
    // androidImg: require("@/assets/img/az/az_online.png"),
    // androidTitle: "安卓下载",
    // wap_phone@2x
    phonelogo: require("@/assets/img/az/outline_bg_wap_2023_01_19.png"),
  },
  introductionService: {
    title: "az澳新通",
    first: "az澳新通是azg公司旗下的优质品牌产品批发服务平台。",
    second:
      "依托azg公司自有仓储物流体系和澳新+中国品质产品体系，通过和多个澳新知名上市公司品牌的战略合作，打造稳定、优质的供应链矩阵。",
    third:
      "为澳新商家/批发商提供打包邮寄自提等一站式服务新体验，并帮助商家实现澳新商品直达国内消费者，中国商品直达澳新消费者的进出口双模式，踏出azg公司“全球好货通全球”愿景的第一步。",
    right_logo: require("@/assets/img/az_down/aoxintong.png"),
    apple_aoxintong_code: AxtConfig.ios_qrcode,
    android_aoxintong_code: AxtConfig.android_qrcode,
  },
  introductionBug: {
    title: "az澳新购",
    first:
      "az澳新购是澳新优质产品品牌服务平台，依托azg公司自有仓库和物流体系，串联澳新药房、澳新线下商超和线下自营店，实现用户买卖打包邮寄一站式的服务需求，并帮助众多优势品牌实现终端渠道到客服端的M2C模式。",
    second:
      "海外华人同胞通过在实体店扫描澳新购下单，az公司直接带打包发货到国内，方便高效。同时az澳新购还引入中国的优质供应链，严格遵循品质把控标准，为海外代购线下店和海外商超等实体店提供丰富的品质货源，用户可以在线下单，在附近门店自提。",
    third:
      "平台帮助澳新华人朋友实现优质产品自用或者海外家人朋友自用。azg公司始终坚信好仓储、好物流才能把优质品牌的产品送到用户手中，实现从厂家直达消费者的优质体验。",
    right_logo: require("@/assets/img/az_down/az_bg_2023_01_19.png"),
    apple_aoxingou_code: AxgConfig.ios_qrcode,
    android_aoxingou_code: AxgConfig.android_qrcode,
  },
  showMore: "了解更多",
  and_download_icon: require("@/assets/img/az_down/and_download_icon.png"),
  apple_download_icon: require("@/assets/img/az_down/apple_download_icon.png"),
  android_az_web_code: require("@/assets/img/az_down/android_az_web_code.png"),
};

//登录页面数据
obj.loginData = {
  pageLeft_BGImg: require("@/assets/img/login/Mask Group@2x.png"),
  forgetPassword: "忘记密码",
  registered: "注册",
  login: "登录",
  forgetPasswordOK: "修改成功，请登录",
  registeredOK: "注册成功，请登录",
  subButName: {
    forgetPassword: "提交修改",
    registered: "提交注册",
    login: "登录",
  },
  wapLogin: {
    login: {
      phone: "手机号",
      password: "请输入密码",
    },
    registered: {
      phone: "手机号",
      code: "验证码",
      password: "设置密码",
      password2: "确认密码",
    },
    forgetPassword: {
      phone: "手机号",
      code: "验证码",
      password: "设置新密码",
      password2: "确认密码",
    },
  },
  xieyi: {
    text1: "我已阅读并同意",
    text2: "用户协议",
    text3: "隐私政策",
  },
  toLogin: {
    text1: "已有账号请",
    text2: "登录",
  },
};

//代理申请
obj.applyAgent = {
  errMsg: {
    email: "邮箱不能为空",
    emailnotallow: "邮箱格式不正确",
    notNull: "不能为空",
    nextStep: "下一步",
  },
  personalApply: {
    ApplyName: "个人版",
    firstName: "邮箱信息",
    secondName: "个人信息",
    idcardName: "申请人姓名",
    idcardNumber: "身份证号",
    idcardALL: "上传个人身份证照片",
    email: "个人邮箱",
    memo: "描述",
    telphone: "电话号码",
    bankName: "开户银行",
    cardNumber: "银行账号",
    dutyParagraph: "公司税号",
    province: "省",
    city: "市",
    region: "申请入驻地址",
    bankCode: "分行代码",
  },
  provinceApply: {
    ApplyName: "省级代理",
    firstName: "公司信息",
    secondName: "法人信息",
    idcardName: "法定代表人姓名",
    idcardNumber: "身份证号",
    idcardALL: "上传法人身份证照片",
    email: "公司邮箱",
    companyName: "境内/外公司名称",
    businessLicenseUrl: "上传单位证件照片",
    memo: "描述",
    telphone: "电话号码",
    bankName: "开户银行",
    cardNumber: "银行账号",
    dutyParagraph: "公司税号",
    province: "省",
    city: "市",
    region: "申请入驻地址",
    bankCode: "分行代码",
  },
  cityApply: {
    ApplyName: "地级代理",
    firstName: "公司信息",
    secondName: "法人信息",
    idcardName: "法定代表人姓名",
    idcardNumber: "身份证号",
    idcardALL: "上传法人身份证照片",
    email: "公司邮箱",
    companyName: "境内/外公司名称",
    businessLicenseUrl: "上传单位证件照片",
    memo: "描述",
    telphone: "电话号码",
    bankName: "开户银行",
    cardNumber: "银行账号",
    dutyParagraph: "公司税号",
    province: "省",
    city: "市",
    region: "申请入驻地址",
    bankCode: "分行代码",
  },
  companyApply: {
    ApplyName: "企业版",
    firstName: "公司信息",
    secondName: "法人信息",
    idcardName: "法定代表人姓名",
    idcardNumber: "身份证号",
    idcardALL: "上传法人身份证照片",
    email: "公司邮箱",
    companyName: "境内/外公司名称",
    businessLicenseUrl: "上传单位证件照片",
    memo: "描述",
    telphone: "电话号码",
    bankName: "开户银行",
    cardNumber: "银行账号",
    dutyParagraph: "公司税号",
    province: "省",
    city: "市",
    region: "申请入驻地址",
    bankCode: "分行代码",
  },
  applyOversea: {
    ApplyName: "az海外",
    applyNameBuy: "az澳新购",
    applyNameService: "az澳新通",
    firstName: "COMPANY INFORMATION | 公司信息",
    secondName: "ADDRESS | 公司地址",
    thirdName: "CONTACT INFORMATION | 联系方式",
    companyName: "Company Name | 境内/外公司名称",
    abn: "Business License No. (ABN) | 澳洲商务号码",
    companyAddress: "Address | 公司地址",
    city: "City | 城市",
    postcode: "Post Code | 邮编",
    idcardName: "Full Name | 全名",
    phone: "Telephone No. | 电话号码",
    email: "Email Address | 邮箱地址",
    telphone: "Telephone No. | 电话号码",
    bankName: "Bank Name | 开户银行",
    cardNumber: "Company Bank Account Name | 账户名",
    dutyParagraph: "公司税号",
    province: "省",
    // city: "市",
    region: "申请入驻地址",
    bankCode: "BSB | 分行代码",
  },
  //修改密码
  changePassword: {
    firstName: "修改密码",
    oldPassword: "请输入旧密码",
    newPassword: "请输入新密码",
    newPassword2: "重复新密码",
  },
  //消息中心
  noticeMsg: {
    firstName: "消息中心",
    msgType: "消息类型",
    msgInfo: "消息内容",
    msgTime: "时间",
  },
};
obj.noticeMsg = {
  onLine: "线上中国",
  outLine: "线下中国",
  AustraliaCompany: "az海外",
  Personal: "店主申请",
  onLinePersonal: "个人申请",
  ChinaCompany: "企业申请",
  provinceApply: "省级代理申请",
  cityApply: "地级市代理申请",
  leaveMsg: "入驻留言",
  applyJoinUs: {
    1: "品牌方",
    2: "总代",
    3: "经销商",
    4: "工厂",
  },
  status: {
    0: ["您好，我们已经收到您的留言，我们会尽快联系您"],
    1: ["审批中", "您好！你的店主申请正在审批中，请耐心等待"],
    2: ["审批通过", "恭喜您，您的店主申请已经通过，请下载app，快速体验"],
    3: ["审批驳回", "您好！你的店主申请被驳回，原因是[提交信息不全]"],
  },
};
obj.applyJoinUs = {
  contactUs: "如果您有想法请下方邮箱联系我们",
  level: [
    {
      name: "品牌方",
      value: 1,
    },
    {
      name: "总代",
      value: 2,
    },
    {
      name: "经销商",
      value: 3,
    },
    {
      name: "工厂",
      value: 4,
    },
  ],
  companyName: "公司名称",
  email: "Email",
  idcardName: "联系人姓名",
  phone: "手机号",
  companyAddress: "公司地址",
  memo: "描述",
  uplodeText: {
    text1: "将文件拖到此处，或",
    text2: "点击上传",
    text3: "如果有资料请上传公司资质材料",
  },
  submit: "提交",
};

obj.azabordData = {
  topImg1: require("@/assets/img/az/Mask Group1@2x.png"),
  wapTopImg1: require("@/assets/img/az/Mask Group2@2x.png"),
};

//首页联系我们图片
obj.contactUsData = {
  pcImg1:
    require("@/assets/img/index/pop-联系我们@2x.jpg") +
    `?t=${new Date().getTime()}`,
  wapImg1:
    require("@/assets/img/index/pop-联系我们@2x (wap).jpg") +
    `?t=${new Date().getTime()}`,
};

// 下载二维码页：我们的优势
obj.erweimaDowlodeData = {
  text1: "我们的优势",
};

// 首页POP品牌入驻
obj.popStoreJion = {
  pcImg1:
    require("@/assets/img/index/pop-品牌旗舰店入驻@2x.jpg") +
    `?t=${new Date().getTime()}`,
  wapImg1:
    require("@/assets/img/index/pop-品牌旗舰店@2x(wap).jpg") +
    `?t=${new Date().getTime()}`,
};

// pop店填写申请信息页面
obj.popFillInInfomation = {
  errMsg: {
    notNull: "不能为空",
    emailnotallow: "邮箱格式不正确",
  },
  // 上方是只用中文版时的临时使用报错文案，
  undefined: "", //一个未取到名字的冗余字段
  longTime: "长期", //长期
  BOX_TITLE: "POP商家入驻申请", //POP商家入驻申请
  // 步骤条
  steps: {
    step1: {
      title: "提交企业主体信息", //填写资质信息
      // description: "约1小时" //请上传相关资料内容
    },
    step2: {
      title: "提交品牌资质信息", //平台审核资料
      // description: "1~3个工作日" //1~3个工作日平台进行资质审核
    },
    step3: {
      title: "提交店铺信息", //入驻完成
      // description: "审核通过，入驻成功" //审核通过，入驻成功
    },
    step4: {
      title: "等待平台审核", //入驻完成
      // description: "审核通过，入驻成功" //审核通过，入驻成功
    },
  },

  // 公司信息
  corporateInfo: {
    BOX_TITLE: "企业信息", //POP商家入驻申请
    subjectType: "主体类型", //主体类型
    zhongguoCorporate: "国内主体", //中国企业
    haiwaiCorporate: "跨境主体", //海外企业
    zhongguoCorporateDesc:
      "适合有营业执照，营业执照“类型”处显示“***公司/企业/个人独资企业”等", //中国企业描述
    haiwaiCorporateDesc:
      "限非大陆注册的公司主体需提供注册登记证, 香港注册公司还需要提供商业登记证(BR)", //海外企业描述
    registerCertificationUrl: "注册登记证明", //注册登记证明
    registerCertificationUrlTipList: [
      "请上传境外公司注册登记证明文件，若存在变更，请一并上传变更文件，", //"请上传境外公司注册登记证明文件，若存在变更，请一并上传变更文件，
      "若为中国香港主体，请上传公司注册证书(CR)以及商业登记证(BR)", // "若为中国香港主体，请上传公司注册证书(CR)以及商业登记证(BR)
    ],
    businessLicenseUrl: "营业执照照片", //营业执照照片
    companyName: "公司名称", //公司名称：
    creditCode: "社会统一信用代码", //社会统一信用代码
    creditCode2: "跨境登记证编号(Certificate NO.)", // 跨境登记证编号(Certificate NO.)
    zhuchedizhi: "营业执照注册地址", //营业执照注册地址：
    address: "详细地址", //详细地址：
    address2: "经营地址", //跨境主体经营地址
    startDate: "公司成立时间", //公司成立时间：
    startDate2: "注册登记日期", //跨境主体注册登记日期
    validPeriod: "经营期限", //经营期限：
    validPeriod2: "注册登记有效期", //跨境主体注册登记有效期
    registeredCapital: "注册资本", //注册资本：
    overseasBusinessCertificationUrl: "境外公司证明", //境外公司证明
  },

  // 法人信息
  legalPersonInfo: {
    BOX_TITLE: "法人信息", //法人信息
    idcardFrontUrl: "身份证人像面", //身份证人像面：
    idcardBackUrl: "身份证国徽面", //身份证国徽面：
    idcardName: "姓名", //姓名：
    idcardNumber: "身份证号", //身份证号：
    idcardStartDate: "身份证有效期起", //身份证有效期起：
    idcardEndDate: "身份证有效期止", //身份证有效期止：
  },
  // 境外授权信息
  authorizationInfo: {
    BOX_TITLE: "境外授权信息", //境外授权信息
    authorizationUrl: "签约授权书", //签约授权书
    authorizationPeriod: "签约授权书有效期", //签约授权书有效期
    authorizerLocationType: "被授权人身份归属地", //被授权人身份归属地
    idcardUrl: "证件照片", //证件照片
    idcardFrontUrl: "身份证人像面", //身份证人像面：
    idcardBackUrl: "身份证国徽面", //身份证国徽面：
    idcardName: "被授权人姓名", //被授权人姓名
    idcardNumber: "被授权人姓名证件号码", //被授权人姓名证件号码
    authorizerCredentialsPeriod: "被授权人证件有效期", //被授权人证件有效期
  },

  // 管理员信息
  administratorInfo: {
    BOX_TITLE: "管理员信息", //管理员信息
    name: "管理员姓名", //姓名：
    email: "管理员邮箱", //邮箱：
    phone: "管理员手机号", //手机号：
    code: "验证码", //验证码：
  },

  // 店铺类型
  BOX_TITLE_POP: "店铺类型", //店铺类型
  popShopInfoObj: {
    shopLogoTipList: [
      "请上传1125*1125px长宽,2M以内,", ////请上传500*500px长宽，2M以内，
      "格式为jpg或png的图片", // 格式为jpg或png的图片
    ],
    shopLogo: "店铺logo", //店铺logo
  },
  popShopInfo: [
    {
      shopType: "旗舰店", //旗舰店：
      value: 1,
      sub: [
        "1、经营一个自有品牌商品的品牌旗舰店；", //1、经营一个自有品牌商品的品牌旗舰店；
        "2、经营多个自有品牌且各品牌归同一实际控制人的品牌旗舰店（邀约制）；", //2、经营多个自有品牌且各品牌归同一实际控制人的品牌旗舰店（邀约制）；
        "3、卖场型品牌（服务类商标）所有者开设的品牌旗舰店（邀约制）；", //3、卖场型品牌（服务类商标）所有者开设的品牌旗舰店（邀约制）；
        "4、开店主体必须是品牌（商标）权利人或持有权利人出具的开设azg平台品牌旗舰店排他性授权文件的企业。", //4、开店主体必须是品牌（商标）权利人或持有权利人出具的开设azg平台品牌旗舰店排他性授权文件的企业。
      ],
    },
    {
      shopType: "专卖店", //专卖店：
      value: 3,
      sub: [
        "1、经营一个授权销售品牌商品的专卖店；", //1、经营一个授权销售品牌商品的专卖店；
        "2、经营多个授权销售品牌且各品牌归同一实际控制人的专卖店（邀约制）；", //2、经营多个授权销售品牌且各品牌归同一实际控制人的专卖店（邀约制）；
        "3、品牌（商标）权利人出具的授权文件不得有地域限制。", //3、品牌（商标）权利人出具的授权文件不得有地域限制。
      ],
    },
    {
      shopType: "专营店", //专营店：
      value: 4,
      sub: [
        "1、经营两个及以上他人品牌商品的专营店；", //1、经营两个及以上他人品牌商品的专营店；
        "2、既经营他人品牌商品又经营自有品牌商品的专营店；", //2、既经营他人品牌商品又经营自有品牌商品的专营店；
        "3、经营两个及以上自有品牌商品的专营店。", //3、经营两个及以上自有品牌商品的专营店。
      ],
    },
    {
      shopType: "卖场旗舰店", //卖场旗舰店
      value: 5,
      sub: [
        "1、商家以服务类型商标（R或TM状态）开设且经营至少两个品牌的店铺；", //1、商家以服务类型商标（R或TM状态）开设且经营至少两个品牌的店铺；
        "2、开店主体必须是卖场品牌（服务类型商标）的权利人或持有权利人开具的独占性授权书的企业；", //2、开店主体必须是卖场品牌（服务类型商标）的权利人或持有权利人开具的独占性授权书的企业；
        "3、店铺内经营的品牌须提供以商标权人为源头的完整授权或相关资质文件", //3、店铺内经营的品牌须提供以商标权人为源头的完整授权或相关资质文件
      ],
    },
  ],
  // 店铺信息
  storeInfo: {
    BOX_TITLE: "店铺信息", //店铺信息
    tradeType: "贸易模式", //贸易模式
    tradeTypeRadio1: "跨境进口保税模式", //跨境进口保税模式
    tradeTypeRadio2: "一般贸易模式", //一般贸易模式
    warehousingMode: "入仓模式", //入仓模式
    warehousingModeRadio1: "商家仓", //商家仓
    warehousingModeRadio2: "平台仓", //平台仓
    warehousingMemo: "是否涉及仓库对接", //是否涉及仓库对接
    warehousingMemoHoldPlace:
      "如需对接请备注商家合作的保税区 及 所使用的ERP系统，否则将影响店铺开通", //是否涉及仓库对接
  },
  // 品牌与资质
  brand: {
    BOX_TITLE: "品牌与资质", //品牌与资质
    // 经营类目
    categoryInfo: {
      category: "经营类目", //经营类目
      butText: "请选择商品类目", //请选择商品类目
    },

    // 商标信息
    brandInfo: {
      BOX_TITLE: "商标信息", //商标信息
      brandName: "品牌名称", //品牌名称
      storeName: "店铺名称", //店铺名称
      mainlandTrademarkLicense: "是否持有中国大陆商标注册证/申请书", //是否持有中国大陆商标注册证/申请书
      mainlandTip: "是：持有港澳台、海外商标资质的商家请选择否。", //是：持有港澳台、海外商标资质的商家请选择否
      shi: "是", //是
      fou: "否", //否
      trademarkSn: "商标注册号", //商标注册号
      trademarkSnOwner: "商标注册人", //商标注册人
      brandLogo: "品牌LOGO", //品牌LOGO
      brandLogoTipList: [
        "请上传500*500px长宽，2M以内,", ////请上传500*500px长宽，2M以内，
        " 格式为jpg或png的图片", // 格式为jpg或png的图片
      ],
      productSource: "商品原产地", //商品原产地
      productSourceTip:
        "非进口：在大陆境外（中国港澳台地区、中国以外的其他国家或地区）生产或销售的商品，通过海关报关验放进入境内，需取得报关单等进口渠道证明文件", //非进口：在大陆境外（中国港澳台地区、中国以外的其他国家或地区）生产或销售的商品，通过海关报关验放进入境内，需取得报关单等进口渠道证明文件
      jinkou: "进口", //进口
      feijinkou: "非进口", //非进口
      companyKind: "公司类型", //公司类型
      companyKindTip:
        "经营企业：销售的商品非入驻公司生产，在其它资质中需提供商品生产企业的相关资质", //经营企业：销售的商品非入驻公司生产，在其它资质中需提供商品生产企业的相关资质
      jingyinQiye: "经营企业", //经营企业
      feijingyinQiye: "生产企业", //生产企业
    },

    // 商标注册证书/商标申请受理通知书
    registration: {
      BOX_TITLE: "商标注册证书/商标申请受理通知书", //商标注册证书/商标申请受理通知书
      certificationChange: "商标是否变更", //商标是否变更
      certificationTip:
        "是：办理过变更，转让，续展，请一并提供变更，转让，续展证明或受理通知书。", //是：办理过变更，转让，续展，请一并提供变更，转让，续展证明或受理通知书
      shi: "是", //是
      fou: "否", //否
      certificationPicUrl: "资质文件", //资质文件
      certificationPicUrlTip:
        "如商标正在审核中，请上传商标申请受理通知书。大小5M以内,支持jpg，png格式的照片，要求内容清晰可见。", //如商标正在审核中，请上传商标申请受理通知书。大小5M以内,支持jpg，png格式的照片，要求内容清晰可见
      certificationTime: "商标有效期", //商标有效期至
    },

    // 商品授权书
    authorization: {
      BOX_TITLE: "商品授权书", //商品授权书
      tipList: [
        "1、以商标持有人为源头出发的，授权至开店公司的完整授权链条", //1、以商标持有人为源头出发的，授权至开店公司的完整授权链条
        "2、商标持有人是开店公司/法人，无需提交授权书", //"2、商标持有人是开店公司/法人，无需提交授权书",
        "3、所有授权都要加盖授权方及开店公司红章", //"3、所有授权都要加盖授权方及开店公司红章"
      ],
      isTrademarkOwner: "是否为品牌商", //是否为品牌商
      isTrademarkOwnerTip: "是：商标持有人是开店公司/法人", //是：商标持有人是开店公司/法人
      shi: "是", //是
      fou: "否", //否
      authorizedCompanyName: "授权公司名", //授权公司名
      salesAuthorizationLetterUrl: "销售授权书", //销售授权书
      salesAuthorizationLetterUrlTip:
        "请上传一张授权书图片，需要加盖授权方及开店公司红章，大小5M以内,支持jpg，png，gif 格式的照片，要求内容清晰可见。", //请上传一张授权书图片，需要加盖授权方及开店公司红章，大小5M以内,支持jpg，png，gif 格式的照片，要求内容清晰可见。
      salesAuthorizationLetterDate: "有效期", //有效期
    },
    //其他资质证明
    other: {
      BOX_TITLE: "其他文件资质", //其他文件资质
      trademarkOwnerOthers: "其他文件资质",
      trademarkOwnerOthersTipList: [
        "如有其它相关资质请将资质上传在此处，（选填项）支持上传多张，", //"如有其它相关资质请将资质上传在此处，（选填项）支持上传多张，
        "大小5M以内,支持jpg，png格式的照片，要求内容清晰可见。", // "大小5M以内,支持jpg，png格式的照片，要求内容清晰可见。"
      ],
    },
    brandType: "品牌类型", //品牌类型
    brandTypeRadio1: "自有品牌", //自有品牌
    brandTypeRadio2: "授权品牌", //授权品牌
    authorizationLevel: "授权等级", //授权等级
    authorizationPeriod: "授权有效期", //授权有效期
    authorizationLongPeriod: "长期", //长期
    certificationPicUrl: "商标注册证", //商标注册证
    brandQualificationUrl: "品牌资质文件", //品牌资质文件
    certificationPicUrlTip:
      "如商标正在审核中，请上传商标申请受理通知书。大小5M以内,支持jpg，png格式的照片，要求内容清晰可见。", //如商标正在审核中，请上传商标申请受理通知书。大小5M以内,支持jpg，png格式的照片，要求内容清晰可见。
  },
  // 其它
  other: {
    memo: "备注信息", //备注信息
  },

  // 审核结果的
  result: {
    resultOk:
      "恭喜您成功成为AZGPOP商家，登录网址和账户已通过短信/邮箱的形式发送给你， 请注意查收", //恭喜您成功成为AZGPOP商家，登录网址和账户已通过短信/邮箱的形式发送给你， 请注意查收
    resultOkCallUs: "如有疑问请电话联系客服：400-188-6999", //如有疑问请电话联系客服：400-188-6999
    resultStatus11: "提交成功！", //提交成功！
    resultStatus12: "小二正在快马加鞭审核中...", //小二正在快马加鞭审核中...
    resultStatus13: "审核预计1-3个工作日，结果请关注短信或公众号通知", //审核预计1-3个工作日，结果请关注短信或公众号通知
    resultStatus14: require("@/assets/img/apply/shenhe_gzh.jpg"), //@/assets/img/apply/shenhe_gzh.jpg
    resultStatus15: "扫一扫关注公众号", //扫一扫关注公众号
  },

  // 下面是提交按钮部分的文案
  submitText: {
    xieyi1: "提交即代表你同意", //提交即代表你同意
    xieyi2: "《商家入驻协议》", //《商家入驻协议》
    submit: "提交", //提交
  },
};

// 供应链入驻指南
obj.applyIntr = {
  stepList: [
    {
      textMain: "提交材料",
      textTime: "约1小时",
      textDetail: "填写并提交品牌，营业执照等信息",
      textLink: "所需资料查询",
    },
    {
      textMain: "平台审核",
      textTime: "1-3个工作日",
      textDetail: "平台进行品牌评估、资质审核",
    },
    {
      textMain: "账户激活",
      textTime: "约30分钟",
      textDetail: "激活商家账号，缴纳保证金",
      textLink: "保证金及入驻资费查询",
    },
    {
      textMain: "店铺上线",
      textTime: "约1小时",
      textDetail: "发布商品，审核通过后即可成功开店",
    },
  ],
  submit: "立即入驻",
};

export { obj };
