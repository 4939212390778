/*
 * @Author: your name
 * @Date: 2021-01-28 15:27:27
 * @LastEditTime: 2025-01-07 14:29:40
 * @LastEditors: hl
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/assets/languages/en.js
 */

import AxgConfig from "../../views/download/store/axg/config";
import AxtConfig from "../../views/download/store/axt/config";

/*
 * @Author: your name
 * @Date: 2021-01-28 15:27:27
 * @LastEditTime: 2021-01-28 19:28:13
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/assets/languages/zh.js
 */
let obj = {
  languages: "EN",
};

// 一些常规名字
obj.toKnowMore = "More information"; //了解更多
obj.nameInputPhone = "Please enter mobile phone number"; //请正确输入手机号
obj.namePhone = "Please enter mobile phone number"; //请输入手机号
obj.nameInputPassword = "Please enter password(6-12 Numbers or Characters)"; //请输入密码（6-12位数字或字母）
obj.namePassword = "Please enter password"; //请输入密码
obj.nameInputPasswordAgin =
  "Please enter password once agin（6-12 Numbers or Characters）"; //请再次输入密码（6-12位数字或字母）
obj.nameInputPasswordNotSame = "Password does not match"; //两次输入密码不相同
obj.nameInputPasswordNotAllow = "password should be 6-12 Numbers or Characters"; //密码应是（6-12位数字或字母）
obj.nameInputYzm = "Please enter the verification code"; //请输入验证码
obj.nameYzm = "verification code"; //验证码
obj.nameLijizhuce = "Sign up"; //立即注册
obj.nameClickzhuce = "Sign Up"; //点击注册
obj.nameBackHome = "Return to the home page"; //返回首页
obj.namewaitReview = "Please wait for manual review"; //请等待人工审核
obj.nameSubSucess = "Submitted successfully"; //提交成功
obj.nameConfirmChange = "Confirm the changes"; //确认修改
obj.nameChangeSucess = "Successfully modified"; //修改成功
obj.getCode = "Obtain security number"; //获取验证码
obj.getCodeOnece = "Retrieve"; //重新获取
obj.daojishi = "seconds wait"; //秒后可重新获取
obj.canNotSubmit = "";
obj.nameInput = "Please enter"; //请输入
//首页顶部菜单列表
obj.barList = [
  {
    name: "AZG Group Introduction", //公司介绍
    id: "1",
  },
  {
    name: "AZG Business Activities", //公司业务
    id: "2",
  },
  {
    name: "Supply Chain", //供应链合作
    id: "3",
  },
  {
    name: "Online Marketing recruitment", //达人入驻
    id: "4",
  },
  {
    name: "News and events", //公司资讯
    id: "5",
  },
  {
    name: "Partnerships", //合作品牌
    id: "6",
  },
];
// 首页顶部登录信息
obj.TopLoginData = [
  {
    name: "Notification", //消息通知
    url: "/noticeMsg",
  },
  {
    name: "Change Password", //修改密码
    url: "/changePassword",
  },
  {
    name: "Sign out", //退出登录
    url: "",
  },
];

// 首页顶部swiper滑块数据
let timeDate = new Date();
// let year = timeDate.getYear(); //获取当前年份(2位)
// let m = timeDate.getMonth() + 1;
// m = m < 10 ? "0" + m : m;
let day = timeDate.getDate();

let swperData = {};

//至3.21号的swiper数据(PC)
swperData.swper_end210321 = [
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end210321/4-pc-new.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: "azabord?prev=outline"
  // },
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end210321/1-pc.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: ""
  // },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/1_2023_11_20.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "https://www.myaz.com/applyIntr",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/1_2024_08_23.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "https://www.myaz.com/azabord?prev=outline",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/3_2024_08_23.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/5_2023_07_19.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/4_2023_07_19.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end220422/2_2023_07_19.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: ""
  // },
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end220422/3.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: "",
  // },
];

//至3.21号的swiper数据(WAP)
swperData.swper_end210321wap = [
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end210321/4-mob-new.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: "azabord?prev=outline"
  // },
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end210321/1-mob.png") +
  //     `?t=${new Date().getTime()}`,
  //   goto: ""
  // },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/11_2023_11_20.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "https://www.myaz.com/applyIntr",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/11_2024_08_23.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "https://www.myaz.com/azabord?prev=outline",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/33_2024_08_23.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/55_2023_07_19.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  {
    imgUrl:
      require("@/assets/img/swper_end220422/44_2023_07_19.jpg") +
      `?t=${new Date().getTime()}`,
    goto: "",
  },
  // {
  //   imgUrl:
  //     require("@/assets/img/swper_end220422/33.jpg") +
  //     `?t=${new Date().getTime()}`,
  //   goto: "",
  // },
];
console.log(day);
// if (day < 15) {
//   obj.TopMenuBarData = swperData.swper_end210314;
//   obj.WapTopMenuBarData = swperData.swper_end210314wap;
// } else if (day < 22) {
//   obj.TopMenuBarData = swperData.swper_end210321;
//   obj.WapTopMenuBarData = swperData.swper_end210321wap;
// } else {
//   obj.TopMenuBarData = swperData.swper_end210314;
//   obj.WapTopMenuBarData = swperData.swper_end210314wap;
// }

obj.TopMenuBarData = swperData.swper_end210321;
obj.WapTopMenuBarData = swperData.swper_end210321wap;

// 首页公司介绍
obj.PcGroupIntroductionData = {
  leftImg: require("@/assets/img/shouye/Mask Group@2x.png"),
  content: {
    title: "AZG Group Introduction",
    details: `azglobal公司2015年于澳洲成立，目前已与香港联合公司李明治先生达成战略合作，同时战略投资了澳洲上市公司Wiseway并为其单一最大股东，战略持股澳洲上市公司Bubs羊奶粉品牌并 为其前15大股东。
    <br>az global公司是澳新多个品牌对华最大的出口公司，拥有多个品牌的独家 出口权，与众多知名药房、品牌合作，在全澳5大城市持有6个大型仓库。凭借其销售渠道体系多个品牌成为了中澳家喻户晓的品牌。`,
  },
};
// wap首页公司介绍
obj.WapIndexIntr = {
  title: "AZG Group Introduction", //公司介绍
  details:
    "Launched in Australia and New Zealand, Growing in China, Reaching out to the world. ", //我们立足澳新，联动国内，走向世界
  more: "More information", //了解更多
};
// wap首页公司业务
obj.WapIndexIntr1 = {
  title: "AZG Business Activities",
  details: "multiple threads", //我们相信，再微小的力量
  details1: "infinite strength", //汇集在一起，也有无限可能
  pictures: [
    {
      img:
        require("@/assets/img/index/x1_en_2023_01_19@2x.png") +
        `?t=${new Date().getTime()}`,
      name: "online",
    },
    {
      img:
        require("@/assets/img/index/xen2.jpg") + `?t=${new Date().getTime()}`,
      name: "outline",
    },
    {
      img:
        require("@/assets/img/index/xen3.jpg") + `?t=${new Date().getTime()}`,
      name: "az",
    },
  ],
  wappictures: [
    {
      img:
        require("@/assets/img/index/wap_xen1.jpg") +
        `?t=${new Date().getTime()}`,
      name: "online",
    },
    {
      img:
        require("@/assets/img/index/wap_xen2.jpg") +
        `?t=${new Date().getTime()}`,
      name: "outline",
    },
    {
      img:
        require("@/assets/img/index/wap_xen3.jpg") +
        `?t=${new Date().getTime()}`,
      name: "az",
    },
  ],
};
// wap首页供应链合作
obj.WapIndexIntr2 = {
  title: "Supply Chain",
  details: "Sourcing the highest quality products,", //我们寻求全球品质好货
  details1: "offering a high quality of life and sharing it with the world.", //分享品位生活，实现全球好货通全球
  more: "contact us", //联系我们
};
// wap首页达人入驻
obj.WapIndexIntr3 = {
  title: "Online Marketing recruitment", //达人入驻
  details: "Official brand influencers, advocating only the best products", //官方合作推品官，分享全球好货，为品质代言
  more: "contact us", //联系我们
  pic: [
    require("@/assets/img/index/wap_daren_en1.jpg") +
      `?t=${new Date().getTime()}`,
    require("@/assets/img/index/wap_daren_en2.jpg") +
      `?t=${new Date().getTime()}`,
    require("@/assets/img/index/wap_daren_en3.jpg") +
      `?t=${new Date().getTime()}`,
  ],
  webPic: [
    require("@/assets/img/index/daren_en1.jpg") + `?t=${new Date().getTime()}`,
    require("@/assets/img/index/daren_en2.jpg") + `?t=${new Date().getTime()}`,
    require("@/assets/img/index/daren_en3.jpg") + `?t=${new Date().getTime()}`,
  ],
};
// wap首页公司资讯
obj.WapIndexIntr4 = {
  title: "News and events", //公司资讯
  pic: [
    require("@/assets/img/shouye/Group 124@2x.png"),
    require("@/assets/img/shouye/Group 125@2x.png"),
    require("@/assets/img/shouye/Group 126@2x.png"),
    require("@/assets/img/shouye/Group 127@2x.png"),
  ],
};
// wap首页合作品牌
obj.WapIndexIntr5 = {
  title: "Partnerships", //合作品牌
  details: "Deep involvement in New Zealand, Japanese, European Industries, ", //公司在澳新产业带、日本产业带、欧洲产业带深入合作
  details1:
    "specialising in the fields of  Cosmetics, Hygiene, Food, Health, Digital and Furnishings", //在美妆个护、食品、保健品、数码家居等领域深度开发合作。
  pic: `https://assets.img.myazstore.com/azg-website/partnerships_wap.png?t=${new Date().getTime()}`,
};
// wap首页底部信息
obj.WapBottom = {
  address: {
    title: "Company Address:", //公司地址：
    add110: "China Headquaters", //中国总部
    add11: "Huaye Development Center, No. 599, Jianye Road", //中国杭州市滨江区
    add1: "Bingjiang District, Hangzhou City, Zhejiang Province, China", //秋溢路601号云狐科技园
    add2: "Hong Kong Headquarters",
    add22: "United Kashima Building, 138 Gloucester Road, Wan Chai, Hong Kong",
    add3: "",
    add33: "",
  },
  relation: {
    title: "Contact:", //联系方式：
    emal: "E-mail：info@alpha-hk.com",
  },
  bottom: {
    bot1: "ICP Business Plan Number: 辽ICP备2022005380号", //ICP备案:浙ICP备19019589号-2
    bot2: "Business Registered Number: 辽B2-20210246", //经营许可证编号:浙B2-20200567
    bot3: "Shenyang Jinyi E-commerce Co., Ltd.", //2022-08-16 时雨添加
  },
  liaoAddress: {
    title: "Company Address:", //公司地址：
    add110: "China Headquaters:", //中国总部
    add11: "Hunnan District, Shenyang City, Liaoning Province, China", //中国辽宁省沈阳市浑南区
    add1: "（1511）7-3 Yingpan North Street,", //营盘北街7-3号（1511）
    add2: "Melbourne Headquarters:",
    add22: "2 Southpark Close Keysborough 3173",
    add3: "Sydney Headquarters:",
    add33: "152 Milperra Rd, Revesby NSW, 2212",
  },
  liaoRelation: {
    title: "Contact:", //联系方式：
    emal: "E-mail：info@alpha-hk.com",
  },
  liaoBottom: {
    bot1: "ICP Business Plan Number: 辽ICP备2021004242号-2",
    bot2: "Network culture business: 辽网文（2021）2008-042号",
  },
};
// 公司介绍信息
obj.WapCompanyIntr = {
  title: "AZG Group Introduction",
  det1:
    "AZ Global group was founded in Australia in 2015, grew rapidly and subsequently entered into a strategic partnership with Mr. Ming Tee Lee the founder of Allied Group Limited, which is one of the largest export companies of Australian and New Zealand brands to China, with exclusive export rights of many brands and deep cooperation with local pharmacies and brands in Australia. We are based in Australia and New Zealand, cultivating the domestic market, and actively establishing a global industry chain model, we also have deep strategic cooperation with many international brands to pursue global buying and selling opportunities.", //公司简介第一段：和多个国际品牌展开深度战略合作，实现全球买全球卖。
  det2:
    "The group has set up branches and teams in Australia, New Zealand, Hong Kong, Macao and Hangzhou, China. Adhering to the cultural values of loyalty, integrity and stability, the group has established independent professional teams in sales, operations, IT production, technology research and development, marketing and other fields, and has built and supported a sales system of more than 50,000 medium and small channels. ", //公司简介第2段：搭建并支持了超5万个中小渠道的销售体系。
  det3:
    "Over the years, the group has focused on the in-depth cooperation with brand sources. It has established strategic cooperation with famous brands such as Bubs、Bellamy's、CapriLac、Capela, etc. At the same time, it has a number of proprietary brands, such as Sanchuangin, Sureness Market, Guoyishi, etc. 80% of our business is conducted through small channels, focusing on the layout and construction of small channels and small businesses, to help and support small and medium-sized enterprises, to provide more employment opportunities for the largest number of people, to meet the distribution needs of brands, so that 80% of earnings go to small channels.", //公司简介第3段：源，多劳多得，让80%的小渠道赚到80%的钱。
  det4:
    "The IT systems such as AZ Global Buy, AZ Good Mart and Australia Central Warehouse independently developed by AZG, cover the whole system of sales, marketing and logistics, improve the internal efficiency of brand and sales channels, reduce the industrial barriers, and thus enhance brand competitiveness. Channel data allows us to predict the shipping capacity of the channel, with the AZG national logistics and warehousing system, to analyse distrbution, to eliminate disjointed supply chains, fake goods and chaotic pricing, to maintain the stability and vitality of the brand. Strategic use of technology allows us to achieve the integration of online and offline, to stimulate the marketing power within the sales system. I this way, we achieve a three-way outcome: maintaining the health of the channel, empowering the channel to serve the user, and gaining the  loyalty of the user to the brand, a win-win situation for all three parties.", //公司简介第4段：渠道服务好用户，用户忠诚于品牌的三方共振，三方共赢的局面。
};
// web az海外
obj.AaAbordTop = {
  azTitle: "Welcome to AZ Online China", //欢迎您加入az海外
  azDet:
    'AZ Internatonal utilises AZG Group\'s international warehouse and delivery infrastructure,connecting overseas Chinese purchasing agencies, offline supermarkets owner-operated stores, wholesalers, distribution agencies, overseas warehouses, cloud warehouse, live broadcasting base and brand promotion business into one seamless system.AZ International provides free warehousing, logistics, packaging and delivery, live broadcast and other services for overseas purchasing agents. It offers major savings in purchasing and warehousing costs, without the risk of stockpiling, and through the "AZ Global Purchasing" APP independently developed by AZ China, makes it a simple matter to open a shop to sell a global selection of goods. AZ International offers a large range of quality products for overseas purchasing by self-operated offline stores and overseas foreign merchants.AZG provides warehousing, logistics, agent delivery and other services, and with the help of AZ online systems, store QR code advertising. Users can place one-click orders by scanning a QR code while shopping online. The AZ warehousing system allows friends and relatives to quickly send overseas products,  enabling achieve national distribution.', //为国内亲朋好友快速送上海外好货，az仓储系统实现全国配送。
  azBtn1: "AZ Aus-NZ Shopping Admission", //企业入驻
  azBtn2: "AZ Aus-NZ Mart Admission",
  azapplyQueryBtn1: "/applyOversea?type=1",
  azapplyQueryBtn2: "/applyOversea?type=2",

  onlineTitle: "Welcome to AZ Online China", //欢迎您加入az线上中国
  onlineDet:
    'To global good quality goods，high standard services，simple casual life as the core concept,we utilise selected high-quality global supply chain resources, with the help of the "AZ Global Purchase" app, independently developed by AZG Group,Our infrastructure incorporates an integrated quality logistics system, into the AZG quality control system,Through diversified marketing, personalized operational training, worry-free after-sales and other integrated services, we enable small and medium-sized channel sellers to reach end consumers and provide a high-quality service experience, creating a high quality, fast and efficient retail experience.', //关于我们：体验触达终端消费者，打造优质、快速、高效的零售新体验。
  onlineBtn1: "Personal Accession", //个人入驻
  onlineapplyQueryBtn1: "/applyAgent?whoApply=personalApply",
  onlineBtn2: "Corporate Admission", //企业入驻
  onlineapplyQueryBtn2: "/applyAgent?whoApply=companyApply",
  outlineTitle: "Welcome to AZ offline China", //欢迎您加入az线下中国
  outlineDet:
    "Utilising AZG Group's global supply chain, warehousing and logistics resources, integrated with the group's marketing resources and core brands, we are able to distribute global products to offline stores across the country, with a comprehensive supply chain that extends from the product's source to the to end consumer.Through a network of provincial/municipal agents, we are seeding the development of  small and medium-sized offline channels nationally, and with the help of the \"AZ Global Supply Chain\" app, providing a new retail experience that is high quality, convenient and efficient.", //帮助万千中小商户更好地服务终端消费者，打造优质、便捷、高效的零售新体验。
  outlineBtn1: "Provincial Agent Settlement", //省级代理入驻
  outlineapplyQueryBtn1: "/applyAgent?whoApply=provinceApply",
  outlineBtn2: "The prefecture-level agent enters", //地级代理入驻
  outlineapplyQueryBtn2: "/applyAgent?whoApply=cityApply",
  steps: [
    {
      title: "Register Login", //注册登入
      det: "User AZ platform select registration login", //用户az平台选择注册登入
    },
    {
      title: "Select a store", //选择店铺
      det: "Select store type", //选择店铺类型
    },
    {
      title: "Submit Document", //提交材料
      det: "Fill in and upload relevant information", //填写并上传相关信息
    },
    {
      title: "Review Qualification", //审核资质
      det: "Qualification review will be completed in 3 working days", //自制审核将于，3个工作日完成
    },
  ],
  azgoodness: [
    {
      // img: require("@/assets/img/az/1_1@2x.png"),
      img: require("@/assets/img/az/1_4@2x.png"),
      title: "One-stop warehousing and logistics", //一站式仓库
      det:
        "AZG's own warehousing system, which integrates warehouse, office, and professional packing and delivery.", //自有仓储体系，集仓库、办公、直播等功能为一体
    },
    {
      img: require("@/assets/img/az/1_2@2x.png"),
      title: "Convenient payment", //支付便捷
      det: "Accurate and transparent costings, multiple payment methods", //精细化成本核算，费用一目了然，多种付款方式
    },
    {
      // img: require("@/assets/img/az/1_3@2x.png"),
      img: require("@/assets/img/az/earth.png"),
      title: "Quality supply chain", //货源丰富
      det: "Australia, New Zealand and China quality supply chain system.", //批量采购和运输的低成本，价格优势，正品保证，品类齐全，爆款库存充足
    },
    // {
    //   img: require("@/assets/img/az/1_4@2x.png"),
    //   title: "A variety of shipping options", //发货多样
    //   det:
    //     "One click order, professional packing, extremely fast delivery, support for domestic bonded warehouse delivery" //一键下单，专业化打包，极速发货，支持国内保税仓发货
    // },
    {
      // img: require("@/assets/img/az/1_5@2x.png"),
      img: require("@/assets/img/az/1_3@2x.png"),
      title: "Marketing support", //营销支持
      det:
        "Brand activities, online live broadcasts, store activity training, etc.", //品牌活动、线上直播、门店活动策划培训等
    },
  ],
  onlinegoodness: [
    {
      img: require("@/assets/img/az/1_111@2x.png"),
      title: "Quality sources", //品质货源
      det:
        "High-quality domestic and foreign sources, authentic guarantee, a full range of products, quality assurance", //优质国内外货源，正品保证，品类齐全，品质保障
    },
    {
      img: require("@/assets/img/az/1_222@2x.png"),
      title: "One-Step Store Opening", //一键开店
      det:
        "Tooled APP, zero cost and one-step to open a store, convenient selection of goods on the goods, personalized store", //工具化APP，零成本一键开店，便捷选货上货，个性化店铺
    },
    {
      img: require("@/assets/img/az/1_333@2x.png"),
      title: "picking pack operation", //azg发货
      det:
        "providing picking and pack services; delivery to end consumers through central warehousing system; minimising the risk of stock holding", //azg中央仓直接发货，无囤货风险
    },
    {
      img: require("@/assets/img/az/1_444@2x.png"),
      title: "Business support", //业务支持
      det: "Taining, fast start, numerous resources, worry-free after sales", //授课培训、快速上手、海量素材、无忧售后
    },
  ],
  outlinegoodness: [
    {
      img: require("@/assets/img/az/1_11@2x.png"),
      title: "AZ integrated sales and service system", //az综合销售服务体系
      det:
        "AZG's professional offline sales empowerment team provides comprehensive marketing training, product knowledge,  promotions strategy and systems training for provincial/municipal representatives to stimulate and support regional sales efforts.", //市场体系维护等服务，充分激发和支持各区域销售积极性。
    },
    {
      img: require("@/assets/img/az/1_22@2x.png"),
      title: "Category structure upgrade", //品类结构升级
      det:
        "The store enjoys the quality supply system of AZ exclusive brand, core brand and private brand, creating synergies between  online and offline modes, and tapping into global sources of quality products.Our system also allows online cross-border sales through QR code advertising displays.", //销售跨境商品，实现线上线下打通，全球品质货源打通。
    },
    {
      img: require("@/assets/img/az/1_33@2x.png"),
      title: "Flexible marketing", //灵活的营销方式
      det:
        "While working within the AZ sales system and the principles of fair competition, provincial/municipal representatives can give full play to their own marketing strategies, working with AZG and brands in their respective regions to stimulate the market and improve sales performance.", //多样化的营销方式，促活市场，推动销售业绩的提升。
    },
  ],
  onlinedownload: {
    // Group 6831@2x
    bgImg: require("@/assets/img/az/online_bg_2023_01_19_2.png"),
    title1: "AZ Global ", //az全球
    title2: "Shopping", //购
    det:
      'AZ Global Shopping is a cross-border + general trade e-commerce platform independently developed by AZG Group, with the core concept of "global good quality goods，high standard services，simple casual life". Utilising high-quality supply chains, providing users with premium brands, premium goods and premium services through worry-free after-sales support and a comprehensive warehousing and logistics system, we empower online small and medium-sized channels, using online social tools, to flourish within a system that is manageable, legal and transparent.', //为用户提供好品牌、好货品、好服务，全程合理合法阳光化。
    logoImg: require("@/assets/img/az/Group 106@2x.png"),
    iosImg: require("@/assets/img/az/ios_online_eq.png"),
    iosTitle: "ios Download", //ios下载
    iosLogo: require("@/assets/img/az/Group 6825@2x.jpg"),
    androidImg: require("@/assets/img/az/quanqiugou_android_cdn.png"),
    // androidImg: require("@/assets/img/az/az_online.png"),
    androidTitle: "Android Download", //安卓下载
    androidLogo: require("@/assets/img/az/Group 6828@2x.jpg"),
  },
  outlinedownload: {
    // bgImg: require("@/assets/img/az/Group 6831@2x.png"),
    bgImg: require("@/assets/img/az/outline_bg_2023_01_19.png"),
    title1: "AZ Good ", //az好货
    title2: "Mart", //通
    det:
      "AZ Good Pass is an intelligent IT system independently developed by AZG Group to empower offline dealers and small and medium-sized channels. Through global sourcing of  quality goods and an exclusive brand portfolio, stable value chain, intelligent marketing tools and data analysis, we help traditional channels to enrich their range, improve the operational efficiency of small stores, and help brands penetrate into small and micro channels.", //提升小店经营效率，帮助品牌渗透到更多的中小，微小渠道。
    logoImg: require("@/assets/img/az/Group 105@2x.png"),
    // logoImg: require("@/assets/img/az/outline_new_android.png"),
    iosImg: require("@/assets/img/az/and_online_eq.png"),
    iosTitle: "ios Download", //ios下载
    iosLogo: require("@/assets/img/az/Group 6825@2x.jpg"),
    androidImg: require("@/assets/img/az/outline_new_android_2023_02_23.jpg"),
    androidTitle: "Android Download", //安卓下载
    androidLogo: require("@/assets/img/az/Group 6828@2x.jpg"),
  },
  waponlinedownload: {
    // title1: "AZ Global ", //az全球
    // title2: "Shopping", //购
    logologo: require("@/assets/img/az/a_logo@2x.png"),
    logofont: require("@/assets/img/az/azlogo-01 3@2x.png"),
    det:
      'AZ Global Shopping is a cross-border + general trade e-commerce platform independently developed by AZG Group, with the core concept of "global good quality goods，high standard services，simple casual life". Utilising high-quality supply chains, providing users with premium brands, premium goods and premium services through worry-free after-sales support and a comprehensive warehousing and logistics system, we empower online small and medium-sized channels, using online social tools, to flourish within a system that is manageable, legal and transparent.', //为用户提供好品牌、好货品、好服务，全程合理合法阳光化。
    logoImg: require("@/assets/img/az/Group 106@2x.png"),
    // iosImg: require("@/assets/img/az/ios_online_eq.png"),
    // iosTitle: "ios Download", //ios下载
    // androidImg: require("@/assets/img/az/az_online.png"),
    // androidTitle: "Android Download", //安卓下载
    // wap_phone@2x
    phonelogo: require("@/assets/img/az/online_bg_wap_2023_01_19_2.png"),
  },
  wapoutlinedownload: {
    // title1: "AZ Global ", //az全球
    // title2: "Shopping", //购
    logologo: require("@/assets/img/az/wap_logo@2x.png"),
    logofont: require("@/assets/img/az/wap_font@2x.png"),
    det:
      'AZ Global Shopping is a cross-border + general trade e-commerce platform independently developed by AZG Group, with the core concept of "global good quality goods，high standard services，simple casual life". Utilising high-quality supply chains, providing users with premium brands, premium goods and premium services through worry-free after-sales support and a comprehensive warehousing and logistics system, we empower online small and medium-sized channels, using online social tools, to flourish within a system that is manageable, legal and transparent.', //为用户提供好品牌、好货品、好服务，全程合理合法阳光化。
    logoImg: require("@/assets/img/az/Group 106@2x.png"),
    // iosImg: require("@/assets/img/az/ios_online_eq.png"),
    // iosTitle: "ios Download",//ios下载
    // androidImg: require("@/assets/img/az/az_online.png"),
    // androidTitle: "Android Download", //安卓下载
    // wap_phone@2x
    phonelogo: require("@/assets/img/az/outline_bg_wap_2023_01_19.png"),
  },
  introductionService: {
    title: "AZ Aus-NZ Mart",
    first:
      "AZ Aus-NZ Mart is the wholesale service platform of AZG Group for quality brand products.",
    second:
      "Relying on AZG Group's own warehousing and logistics system and the Australia-New Zealand + China quality product system, through the strategic cooperation with many famous listed brands in Australia and New Zealand, we create a stable and high-quality supply chain matrix to provide a new one-stop service experience for Australian merchants/wholesalers. ",
    third:
      "The service encompasses packing, mailing and self-pickup, and gives merchants access to the dual import/export modes of Australia-New Zealand goods directly to domestic consumers and Chinese goods directly to Australia-New Zealand consumers. AZG’s vision is to create a platform that facilitates the international exchange of goods, allowing customers to access global brands, and the AZ Aus-NZ Mart platform is a first step in that direction.",
    apple_aoxintong_code: AxtConfig.ios_qrcode,
    android_aoxintong_code: AxtConfig.android_qrcode,
  },
  introductionBug: {
    title: "AZ Aus-NZ Shopping",
    first:
      "AZ Aus-NZ Shopping is a quality product brand service platform based in Australia-New Zealand, utilising AZG Group's own warehouse and logistics system, connecting pharmacies, bricks-and-mortar supermarkets and self-operated stores. It is designed to serve the needs of customers for a one-stop solution for buying, selling, packing and mailing, and to help many high-quality brands supply directly to end consumers.",
    second:
      " Overseas Chinese compatriots place orders by scanning the Australia-New Zealand products in physical stores, and AZ Group delivers the package directly to China. AZ Australia-New Zealand also also utilises China's high-quality supply chain and follows strict follows quality control standards to supply a rich variety of products to overseas stores, where users can place orders online and pick them up at nearby retail outlets. ",
    third:
      "The platform enables Chinese friends in Australia and New Zealand to access quality products for their own use or for their family and friends overseas. AZG Group strongly believes that good warehousing and good logistics are the only way to deliver quality brand products to users and create a quality experience from manufacturers directly to consumers. ",
    right_logo: require("@/assets/img/az_down/az_bg_2023_01_19.png"),
    apple_aoxingou_code: AxgConfig.ios_qrcode,
    android_aoxingou_code: AxgConfig.android_qrcode,
  },
  showMore: "Show More",
  and_download_icon: require("@/assets/img/az_down/and_download_icon.png"),
  apple_download_icon: require("@/assets/img/az_down/apple_download_icon.png"),
  android_az_web_code: require("@/assets/img/az_down/android_az_web_code.png"),
};

//登录页面数据
obj.loginData = {
  pageLeft_BGImg: require("@/assets/img/login/Mask Group@2x.png"),
  forgetPassword: "Forgotten Password", //忘记密码
  registered: "Sign Up", //注册
  login: "Log in", //登录
  forgetPasswordOK: "Updated successfully, please log in", //修改成功，请登录
  registeredOK: "Registration is successful, please log in", //注册成功，请登录
  subButName: {
    forgetPassword: "Submit changes", //提交修改
    registered: "Submit registration", //提交注册
    login: "Log in", //登录
  },
  wapLogin: {
    login: {
      phone: "Phone number", //手机号
      password: "Please enter password", //请输入密码
    },
    registered: {
      phone: "Phone number", //手机号
      code: "security number", //验证码
      password: "Create password", //设置密码
      password2: "Confirm password", //确认密码
    },
    forgetPassword: {
      phone: "Phone number", //手机号
      code: "security number", //验证码
      password: "Create new password", //设置新密码
      password2: "Confirm password", //确认密码
    },
  },
  xieyi: {
    text1: "I have read and agree", //我已阅读并同意
    text2: "User Agreement", //用户协议
    text3: "Privacy Policy", //隐私政策
  },
  toLogin: {
    text1: "Existing Account Log in", //已有账号请
    text2: "Log in", //登录
  },
};

//代理申请
obj.applyAgent = {
  errMsg: {
    email: "E-mail cannot be empty", //邮箱不能为空
    emailnotallow: "E-mail format is incorrect", //邮箱格式不正确
    notNull: "Cannot be empty", //不能为空
    nextStep: "Next step", //下一步
  },
  personalApply: {
    ApplyName: "Personal Accession", //个人版
    firstName: "Email information", //邮箱信息
    secondName: "Personal information", //个人信息
    idcardName: "Applicant's name", //申请人姓名
    idcardNumber: "ID number", //身份证号
    idcardALL: "Upload personal ID photo", //上传个人身份证照片
    email: "Personal E-mail", //个人邮箱
    memo: "Description", //描述
    telphone: "Telephone NO.", // 手机号码
    bankName: "Bank Name", // 银行名称
    cardNumber: "Bank Account", //银行账号
    dutyParagraph: "Company Id Number", // 公司税号
    province: "Province", // 省
    city: "City", // 市
    region: "Application Address", // 申请入驻地址
  },
  provinceApply: {
    ApplyName: "Provincial Agent", //省级代理
    firstName: "Your company information", //公司信息
    secondName: "Information of Legal Representative", //法人信息
    idcardName: "Name of Legal Representative", //法定代表人姓名
    idcardNumber: "ID number", //身份证号
    idcardALL: "Upload legal person ID card photo", //上传法人身份证照片
    email: "Company email", //公司邮箱
    companyName: "Name of domestic/foreign company", //境内/外公司名称
    businessLicenseUrl: "Upload photo of the company ID", //上传单位证件照片
    memo: "Description", //描述
    telphone: "Telephone NO.", // 手机号码
    bankName: "Bank Name", // 银行名称
    cardNumber: "Bank Account", //银行账号
    dutyParagraph: "Company Id Number", // 公司税号
    province: "Province", // 省
    city: "City", // 市
    region: "Application Address", // 申请入驻地址
  },
  cityApply: {
    ApplyName: "Prefecture-level agent", //地级代理
    firstName: "Your company information", //公司信息
    secondName: "Information of Legal Representative", //法人信息
    idcardName: "Name of Legal Representative", //法定代表人姓名
    idcardNumber: "ID number", //身份证号
    idcardALL: "Upload legal person ID card photo", //上传法人身份证照片
    email: "Company email", //公司邮箱
    companyName: "Name of domestic/foreign company", //境内/外公司名称
    businessLicenseUrl: "Upload photo of the company ID", //上传单位证件照片
    memo: "Description", //描述
    telphone: "Telephone NO.", // 手机号码
    bankName: "Bank Name", // 银行名称
    cardNumber: "Bank Account", //银行账号
    dutyParagraph: "Company Id Number", // 公司税号
    province: "Province", // 省
    city: "City", // 市
    region: "Application Address", // 申请入驻地址
  },
  companyApply: {
    ApplyName: "企业版",
    firstName: "Your company information", //公司信息
    secondName: "Information of Legal Representative", //法人信息
    idcardName: "Name of Legal Representative", //法定代表人姓名
    idcardNumber: "ID number", //身份证号
    idcardALL: "Upload legal person ID card photo", //上传法人身份证照片
    email: "Company email", //公司邮箱
    companyName: "Name of domestic/foreign company", //境内/外公司名称
    businessLicenseUrl: "Upload photo of the company ID", //上传单位证件照片
    memo: "Description", //描述
    telphone: "Telephone NO.", // 手机号码
    bankName: "Bank Name", // 银行名称
    cardNumber: "Bank Account", //银行账号
    dutyParagraph: "Company Id Number", // 公司税号
    province: "Province", // 省
    city: "City", // 市
    region: "Application Address", // 申请入驻地址
  },
  applyOversea: {
    ApplyName: "az海外",
    applyNameBuy: "AZ Aus-NZ Shopping",
    applyNameService: "AZ Aus-NZ Mart",
    firstName: "COMPANY INFORMATION | 公司信息",
    secondName: "ADDRESS | 公司地址",
    thirdName: "CONTACT INFORMATION | 联系方式",
    companyName: "Company Name | 境内/外公司名称",
    abn: "Business License No. (ABN) | 澳洲商务号码",
    companyAddress: "Address | 公司地址",
    city: "City | 城市",
    postcode: "Post Code | 邮编",
    idcardName: "Full Name | 全名",
    phone: "Telephone No. | 电话号码",
    email: "Email Address | 邮箱地址",
    telphone: "Telephone No. | 电话号码",
    bankName: "Bank Name | 开户银行",
    cardNumber: "Company Bank Account Name | 账户名",
    dutyParagraph: "公司税号",
    province: "省",
    region: "申请入驻地址",
    bankCode: "BSB | 分行代码",
  },
  //修改密码
  changePassword: {
    firstName: "Change Password", //修改密码
    oldPassword: "Please enter the old password", //请输入旧密码
    newPassword: "Please enter the new password", //请输入新密码
    newPassword2: "Repeat new password", //重复新密码
  },
  //消息中心
  noticeMsg: {
    firstName: "Message center",
    msgType: "Message Type", //消息类型
    msgInfo: "Message content", //消息内容
    msgTime: "Time", //时间
  },
};
obj.noticeMsg = {
  onLine: "Online in China", //线上中国
  outLine: "Offline Distribution in China", //线下中国
  AustraliaCompany: "AZG Worldwide Trading", //az海外
  Personal: "Shop Owner Application", //店主申请
  onLinePersonal: "Personal application", //个人申请
  ChinaCompany: "企业申请",
  provinceApply: "Provincial Agent Application", //省级代理申请
  cityApply: "Local Agent Application", //地级市代理申请
  leaveMsg: "message", //入驻留言
  applyJoinUs: {
    1: "Brand side", //品牌方
    2: "General Agent", //总代
    3: "Distributor", //经销商
    4: "Factory", //工厂
  },
  status: {
    0: [
      "Hello, we have received your message, we will contact you as soon as possible!",
    ], // ["您好，我们已经收到您的留言，我们会尽快联系您"],
    1: [
      "Approval in progress",
      "Hello! Your application is being approved, we will contact you shortly.",
    ], //["审批中", "您好！你的店主申请正在审批中，请耐心等待"],
    2: [
      "Approved",
      "Congratulations, your shopkeeper application has been approved, please download the app for a quick experience",
    ], // ["审批通过", "恭喜您，您的店主申请已经通过，请下载app，快速体验"]
    3: [
      "Approval rejected",
      "Hello! Your store owner application has been rejected due to [incomplete information submitted]",
    ], //["审批驳回", "您好！你的店主申请被驳回，原因是[提交信息不全]"]
  },
};
obj.applyJoinUs = {
  contactUs: "If you have an idea please contact us at the email below", //如果您有想法请下方邮箱联系我们
  level: [
    {
      name: "Brand side", //品牌方
      value: 1,
    },
    {
      name: "General Agent", //总代
      value: 2,
    },
    {
      name: "Distributor", //经销商
      value: 3,
    },
    {
      name: "Factory", //工厂
      value: 4,
    },
  ],
  companyName: "Company Name", //公司名称
  email: "Email",
  idcardName: "Contact Name", //联系人姓名
  phone: "Telephone NO", //手机号
  companyAddress: "Company Address", //公司地址
  memo: "Description", //描述
  uplodeText: {
    text1: "Drag the file here, or", //将文件拖到此处，或
    text2: "Click to upload", //点击上传
    text3: "Please upload company qualification materials if you have them", //如果有资料请上传公司资质材料
  },
  submit: "Submit", //提交
};

obj.azabordData = {
  topImg1: require("@/assets/img/az/Mask Group@2x.jpg"),
  wapTopImg1: require("@/assets/img/az/Mask Group@2x2.jpg"),
};

//首页联系我们图片
obj.contactUsData = {
  pcImg1:
    require("@/assets/img/index/pop-联系我们@2x.jpg") +
    `?t=${new Date().getTime()}`,
  wapImg1:
    require("@/assets/img/index/pop-联系我们@2x (wap).jpg") +
    `?t=${new Date().getTime()}`,
};

// 下载二维码页：我们的优势
obj.erweimaDowlodeData = {
  text1: "Our advantage",
};

// 首页POP品牌入驻
obj.popStoreJion = {
  pcImg1:
    require("@/assets/img/index/pop-品牌旗舰店入驻@2x.jpg") +
    `?t=${new Date().getTime()}`,
  wapImg1:
    require("@/assets/img/index/pop-品牌旗舰店@2x(wap).jpg") +
    `?t=${new Date().getTime()}`,
};

// pop店填写申请信息页面
obj.popFillInInfomation = {
  errMsg: {
    notNull: "不能为空",
    emailnotallow: "邮箱格式不正确",
  },
  // 上方是只用中文版时的临时使用报错文案，
  undefined: "", //一个未取到名字的冗余字段
  longTime: "长期", //长期
  BOX_TITLE: "POP商家入驻申请", //POP商家入驻申请
  // 步骤条
  steps: {
    step1: {
      title: "提交企业主体信息", //填写资质信息
      // description: "约1小时" //请上传相关资料内容
    },
    step2: {
      title: "提交品牌资质信息", //平台审核资料
      // description: "1~3个工作日" //1~3个工作日平台进行资质审核
    },
    step3: {
      title: "提交店铺信息", //入驻完成
      // description: "审核通过，入驻成功" //审核通过，入驻成功
    },
    step4: {
      title: "等待平台审核", //入驻完成
      // description: "审核通过，入驻成功" //审核通过，入驻成功
    },
  },

  // 公司信息
  corporateInfo: {
    BOX_TITLE: "企业信息", //POP商家入驻申请
    subjectType: "主体类型", //主体类型
    zhongguoCorporate: "国内主体", //中国企业
    haiwaiCorporate: "跨境主体", //海外企业
    zhongguoCorporateDesc:
      "适合有营业执照，营业执照“类型”处显示“***公司/企业/个人独资企业”等", //中国企业描述
    haiwaiCorporateDesc:
      "限非大陆注册的公司主体需提供注册登记证, 香港注册公司还需要提供商业登记证(BR)", //海外企业描述
    registerCertificationUrl: "注册登记证明", //注册登记证明
    registerCertificationUrlTipList: [
      "请上传境外公司注册登记证明文件，若存在变更，请一并上传变更文件，", //"请上传境外公司注册登记证明文件，若存在变更，请一并上传变更文件，
      "若为中国香港主体，请上传公司注册证书(CR)以及商业登记证(BR)", // "若为中国香港主体，请上传公司注册证书(CR)以及商业登记证(BR)
    ],
    businessLicenseUrl: "营业执照照片", //营业执照照片
    companyName: "公司名称", //公司名称：
    creditCode: "社会统一信用代码", //社会统一信用代码
    creditCode2: "社会统一信用代码", // 跨境登记证编号(Certificate NO.)
    zhuchedizhi: "营业执照注册地址", //营业执照注册地址：
    address: "详细地址", //详细地址：
    address2: "经营地址", //跨境主体经营地址
    startDate: "公司成立时间", //公司成立时间：
    startDate2: "注册登记日期", //跨境主体注册登记日期
    validPeriod: "经营期限", //经营期限：
    validPeriod2: "注册登记有效期", //跨境主体注册登记有效期
    registeredCapital: "注册资本", //注册资本：
    overseasBusinessCertificationUrl: "境外公司证明", //境外公司证明
  },

  // 法人信息
  legalPersonInfo: {
    BOX_TITLE: "法人信息", //法人信息
    idcardFrontUrl: "身份证人像面", //身份证人像面：
    idcardBackUrl: "身份证国徽面", //身份证国徽面：
    idcardName: "姓名", //姓名：
    idcardNumber: "身份证号", //身份证号：
    idcardStartDate: "身份证有效期起", //身份证有效期起：
    idcardEndDate: "身份证有效期止", //身份证有效期止：
  },
  // 境外授权信息
  authorizationInfo: {
    BOX_TITLE: "境外授权信息", //境外授权信息
    authorizationUrl: "签约授权书", //签约授权书
    authorizationPeriod: "签约授权书有效期", //签约授权书有效期
    authorizerLocationType: "被授权人身份归属地", //被授权人身份归属地
    idcardUrl: "证件照片", //证件照片
    idcardFrontUrl: "身份证人像面", //身份证人像面：
    idcardBackUrl: "身份证国徽面", //身份证国徽面：
    idcardName: "被授权人姓名", //被授权人姓名
    idcardNumber: "被授权人姓名证件号码", //被授权人姓名证件号码
    authorizerCredentialsPeriod: "被授权人证件有效期", //被授权人证件有效期
  },

  // 管理员信息
  administratorInfo: {
    BOX_TITLE: "管理员信息", //管理员信息
    name: "管理员姓名", //姓名：
    email: "管理员邮箱", //邮箱：
    phone: "管理员手机号", //手机号：
    code: "验证码", //验证码：
  },

  // 店铺类型
  BOX_TITLE_POP: "店铺类型", //店铺类型
  popShopInfoObj: {
    shopLogoTipList: [
      "请上传1125*1125px长宽,2M以内,", ////请上传500*500px长宽，2M以内，
      "格式为jpg或png的图片", // 格式为jpg或png的图片
    ],
    shopLogo: "店铺logo", //店铺logo
  },
  popShopInfo: [
    {
      shopType: "旗舰店", //旗舰店：
      value: 1,
      sub: [
        "1、经营一个自有品牌商品的品牌旗舰店；", //1、经营一个自有品牌商品的品牌旗舰店；
        "2、经营多个自有品牌且各品牌归同一实际控制人的品牌旗舰店（邀约制）；", //2、经营多个自有品牌且各品牌归同一实际控制人的品牌旗舰店（邀约制）；
        "3、卖场型品牌（服务类商标）所有者开设的品牌旗舰店（邀约制）；", //3、卖场型品牌（服务类商标）所有者开设的品牌旗舰店（邀约制）；
        "4、开店主体必须是品牌（商标）权利人或持有权利人出具的开设azg平台品牌旗舰店排他性授权文件的企业。", //4、开店主体必须是品牌（商标）权利人或持有权利人出具的开设azg平台品牌旗舰店排他性授权文件的企业。
      ],
    },
    {
      shopType: "专卖店", //专卖店：
      value: 3,
      sub: [
        "1、经营一个授权销售品牌商品的专卖店；", //1、经营一个授权销售品牌商品的专卖店；
        "2、经营多个授权销售品牌且各品牌归同一实际控制人的专卖店（邀约制）；", //2、经营多个授权销售品牌且各品牌归同一实际控制人的专卖店（邀约制）；
        "3、品牌（商标）权利人出具的授权文件不得有地域限制。", //3、品牌（商标）权利人出具的授权文件不得有地域限制。
      ],
    },
    {
      shopType: "专营店", //专营店：
      value: 4,
      sub: [
        "1、经营两个及以上他人品牌商品的专营店；", //1、经营两个及以上他人品牌商品的专营店；
        "2、既经营他人品牌商品又经营自有品牌商品的专营店；", //2、既经营他人品牌商品又经营自有品牌商品的专营店；
        "3、经营两个及以上自有品牌商品的专营店。", //3、经营两个及以上自有品牌商品的专营店。
      ],
    },
    {
      shopType: "卖场旗舰店", //卖场旗舰店
      value: 5,
      sub: [
        "1、商家以服务类型商标（R或TM状态）开设且经营至少两个品牌的店铺；", //1、商家以服务类型商标（R或TM状态）开设且经营至少两个品牌的店铺；
        "2、开店主体必须是卖场品牌（服务类型商标）的权利人或持有权利人开具的独占性授权书的企业；", //2、开店主体必须是卖场品牌（服务类型商标）的权利人或持有权利人开具的独占性授权书的企业；
        "3、店铺内经营的品牌须提供以商标权人为源头的完整授权或相关资质文件", //3、店铺内经营的品牌须提供以商标权人为源头的完整授权或相关资质文件
      ],
    },
  ],
  // 店铺信息
  storeInfo: {
    BOX_TITLE: "店铺信息", //店铺信息
    tradeType: "贸易模式", //贸易模式
    tradeTypeRadio1: "跨境进口保税模式", //跨境进口保税模式
    tradeTypeRadio2: "一般贸易模式", //一般贸易模式
    warehousingMode: "入仓模式", //入仓模式
    warehousingModeRadio1: "商家仓", //商家仓
    warehousingModeRadio2: "平台仓", //平台仓
    warehousingMemo: "是否涉及仓库对接", //是否涉及仓库对接
    warehousingMemoHoldPlace:
      "如需对接请备注商家合作的保税区 及 所使用的ERP系统，否则将影响店铺开通", //是否涉及仓库对接
  },
  // 品牌与资质
  brand: {
    BOX_TITLE: "品牌与资质", //品牌与资质
    // 经营类目
    categoryInfo: {
      category: "经营类目", //经营类目
      butText: "请选择商品类目", //请选择商品类目
    },

    // 商标信息
    brandInfo: {
      BOX_TITLE: "商标信息", //商标信息
      brandName: "品牌名称", //品牌名称
      storeName: "店铺名称", //店铺名称
      mainlandTrademarkLicense: "是否持有中国大陆商标注册证/申请书", //是否持有中国大陆商标注册证/申请书
      mainlandTip: "是：持有港澳台、海外商标资质的商家请选择否。", //是：持有港澳台、海外商标资质的商家请选择否
      shi: "是", //是
      fou: "否", //否
      trademarkSn: "商标注册号", //商标注册号
      trademarkSnOwner: "商标注册人", //商标注册人
      brandLogo: "品牌LOGO", //品牌LOGO
      brandLogoTipList: [
        "请上传500*500px长宽，2M以内,", ////请上传500*500px长宽，2M以内，
        " 格式为jpg或png的图片", // 格式为jpg或png的图片
      ],
      productSource: "商品原产地", //商品原产地
      productSourceTip:
        "非进口：在大陆境外（中国港澳台地区、中国以外的其他国家或地区）生产或销售的商品，通过海关报关验放进入境内，需取得报关单等进口渠道证明文件", //非进口：在大陆境外（中国港澳台地区、中国以外的其他国家或地区）生产或销售的商品，通过海关报关验放进入境内，需取得报关单等进口渠道证明文件
      jinkou: "进口", //进口
      feijinkou: "非进口", //非进口
      companyKind: "公司类型", //公司类型
      companyKindTip:
        "经营企业：销售的商品非入驻公司生产，在其它资质中需提供商品生产企业的相关资质", //经营企业：销售的商品非入驻公司生产，在其它资质中需提供商品生产企业的相关资质
      jingyinQiye: "经营企业", //经营企业
      feijingyinQiye: "生产企业", //生产企业
    },

    // 商标注册证书/商标申请受理通知书
    registration: {
      BOX_TITLE: "商标注册证书/商标申请受理通知书", //商标注册证书/商标申请受理通知书
      certificationChange: "商标是否变更", //商标是否变更
      certificationTip:
        "是：办理过变更，转让，续展，请一并提供变更，转让，续展证明或受理通知书。", //是：办理过变更，转让，续展，请一并提供变更，转让，续展证明或受理通知书
      shi: "是", //是
      fou: "否", //否
      certificationPicUrl: "资质文件：", //资质文件
      certificationPicUrlTip:
        "如商标正在审核中，请上传商标申请受理通知书。大小5M以内,支持jpg，png格式的照片，要求内容清晰可见。", //如商标正在审核中，请上传商标申请受理通知书。大小5M以内,支持jpg，png格式的照片，要求内容清晰可见
      certificationTime: "商标有效期", //商标有效期至
    },

    // 商品授权书
    authorization: {
      BOX_TITLE: "商品授权书", //商品授权书
      tipList: [
        "1、以商标持有人为源头出发的，授权至开店公司的完整授权链条", //1、以商标持有人为源头出发的，授权至开店公司的完整授权链条
        "2、商标持有人是开店公司/法人，无需提交授权书", //"2、商标持有人是开店公司/法人，无需提交授权书",
        "3、所有授权都要加盖授权方及开店公司红章", //"3、所有授权都要加盖授权方及开店公司红章"
      ],
      isTrademarkOwner: "是否为品牌商", //是否为品牌商
      isTrademarkOwnerTip: "是：商标持有人是开店公司/法人", //是：商标持有人是开店公司/法人
      shi: "是", //是
      fou: "否", //否
    },
    //其他资质证明
    other: {
      BOX_TITLE: "其他文件资质", //其他文件资质
      trademarkOwnerOthers: "其他文件资质",
      trademarkOwnerOthersTipList: [
        "如有其它相关资质请将资质上传在此处，（选填项）支持上传多张，", //"如有其它相关资质请将资质上传在此处，（选填项）支持上传多张，
        "大小5M以内,支持jpg，png格式的照片，要求内容清晰可见。", // "大小5M以内,支持jpg，png格式的照片，要求内容清晰可见。"
      ],
    },
    brandType: "品牌类型", //品牌类型
    brandTypeRadio1: "自有品牌", //自有品牌
    brandTypeRadio2: "授权品牌", //授权品牌
    authorizationLevel: "授权等级", //授权等级
    authorizationPeriod: "授权有效期", //授权有效期
    authorizationLongPeriod: "长期", //长期
    certificationPicUrl: "商标注册证", //商标注册证
    brandQualificationUrl: "品牌资质文件", //品牌资质文件
    certificationPicUrlTip:
      "如商标正在审核中，请上传商标申请受理通知书。大小5M以内,支持jpg，png格式的照片，要求内容清晰可见。", //如商标正在审核中，请上传商标申请受理通知书。大小5M以内,支持jpg，png格式的照片，要求内容清晰可见。
  },
  // 其它
  other: {
    memo: "备注信息", //备注信息
  },

  // 审核结果的
  result: {
    resultOk:
      "恭喜您成功成为AZGPOP商家，登录网址和账户已通过短信/邮箱的形式发送给你， 请注意查收", //恭喜您成功成为AZGPOP商家，登录网址和账户已通过短信/邮箱的形式发送给你， 请注意查收
    resultOkCallUs: "如有疑问请电话联系客服：400-188-6999", //如有疑问请电话联系客服：400-188-6999
    resultStatus11: "提交成功！", //提交成功！
    resultStatus12: "小二正在快马加鞭审核中...", //小二正在快马加鞭审核中...
    resultStatus13: "审核预计1-3个工作日，结果请关注短信或公众号通知", //审核预计1-3个工作日，结果请关注短信或公众号通知
    resultStatus14: require("@/assets/img/apply/shenhe_gzh.jpg"), //@/assets/img/apply/shenhe_gzh.jpg
    resultStatus15: "扫一扫关注公众号", //扫一扫关注公众号
  },

  // 下面是提交按钮部分的文案
  submitText: {
    xieyi1: "提交即代表你同意", //提交即代表你同意
    xieyi2: "《商家入驻协议》", //《商家入驻协议》
    submit: "提交", //提交
  },
};

// 供应链入驻指南
obj.applyIntr = {
  stepList: [
    {
      textMain: "提交材料",
      textTime: "约1小时",
      textDetail: "填写并提交品牌，营业执照等信息",
      textLink: "所需资料查询",
    },
    {
      textMain: "平台审核",
      textTime: "1-3个工作日",
      textDetail: "平台进行品牌评估、资质审核",
    },
    {
      textMain: "账户激活",
      textTime: "约30分钟",
      textDetail: "激活商家账号，缴纳保证金",
      textLink: "保证金及入驻资费查询",
    },
    {
      textMain: "店铺上线",
      textTime: "约1小时",
      textDetail: "发布商品，审核通过后即可成功开店",
    },
  ],
  submit: "立即入驻",
};

export { obj };
