<template>
  <div class="index_con">
    <div v-if="!system.isPhone">
      <!-- 顶部菜单栏 -->
      <top-menu-bar
        :showBar="true"
        :bgStyle="bgStyle"
        :opacityStyle="opacityStyle"
        :curScroll="curScroll"
        @curIndex="curIndex"
      />
      <!-- 顶部轮播图块 -->
      <pc-top-sweiper />
      <!-- 公司介绍 -->
      <from-world
        id="1"
        :title="$t('WapIndexIntr.title')"
        :details="$t('WapIndexIntr.details')"
        :more="$t('WapIndexIntr.more')"
        :path="'/companyintr'"
      />
      <div class="none">
        <grop-intr />
      </div>
      <!-- 公司业务 -->
      <from-world
        id="2"
        :title="$t('WapIndexIntr1.title')"
        :details="$t('WapIndexIntr1.details') + $t('WapIndexIntr1.details1')"
      />
      <companty-con />
      <!-- 供应链合作 -->
      <from-world
        id="3"
        :title="$t('WapIndexIntr2.title')"
        :details="
          $t('WapIndexIntr2.details') + '，' + $t('WapIndexIntr2.details1')
        "
      />
      <div class="none pop_img">
        <!--pop品牌旗舰店入住图片 -->
        <div>
          <img
            @click="goPath('/applyIntr')"
            :src="$t('popStoreJion.pcImg1')"
            alt=""
            class="rela_img can_click"
          />
        </div>
        <!--联系我们图片 -->
        <div>
          <img
            @click="goPath('/applyJoinUs')"
            :src="$t('contactUsData.pcImg1')"
            alt=""
            class="rela_img can_click"
          />
        </div>
      </div>
      <!-- 达人入驻 2023-06-13隐藏 -->
      <!-- <from-world
        id="4"
        :title="$t('WapIndexIntr3.title')"
        :details="$t('WapIndexIntr3.details')"
      />
      <stare-com :pic="$t('WapIndexIntr3.webPic')" /> -->
      <!-- 公司资讯 -->
      <!-- <from-world id="5" :title="$t('WapIndexIntr4.title')" />
      <company-intr :pic="$t('WapIndexIntr4.pic')" /> -->
      <!-- 合作品牌 -->
      <from-world id="6" :title="$t('WapIndexIntr5.title')" />
      <div class="some_com">
        <img
          :src="
            `https://assets.img.myazstore.com/azg-website/partnerships_pc.png?t=${new Date().getTime()}`
          "
          alt=""
        />
      </div>
      <div id="7" ref="content" class="content">
        <bottom-det />
      </div>
    </div>
    <!-- 
      wap端
     -->
    <div v-if="system.isPhone">
      <bar-wap
        :showBar="true"
        :bgStyle="bgStyle"
        :opacityStyle="opacityStyle"
        :curScroll="curScroll"
        @curIndex="curIndex"
      />
      <wap-top-sweiper />
      <!-- 公司介绍 -->
      <wap-from-world
        id="1"
        :title="$t('WapIndexIntr.title')"
        :details="$t('WapIndexIntr.details')"
        :more="$t('WapIndexIntr.more')"
        :path="'/companyintr'"
      />
      <div class="none">
        <!-- <img
          src="@/assets/img/index/wapRectangle 247@2x.png"
          alt=""
          class="rela_img"
        /> -->
        <wap-grop-intr />
      </div>
      <!-- 公司业务 -->
      <wap-from-world
        id="2"
        :title="$t('WapIndexIntr1.title')"
        :details="$t('WapIndexIntr1.details')"
        :details1="$t('WapIndexIntr1.details1')"
      />
      <wap-companty-con />
      <!-- 供应链合作 -->
      <wap-from-world
        id="3"
        :title="$t('WapIndexIntr2.title')"
        :details="$t('WapIndexIntr2.details')"
        :details1="$t('WapIndexIntr2.details1')"
      />
      <div class="none pop_img">
        <!--pop品牌旗舰店入住图片 -->
        <div>
          <img
            @click="goPath('/applyPop')"
            :src="$t('popStoreJion.wapImg1')"
            alt=""
            class="rela_img"
          />
        </div>
        <!--联系我们图片 -->
        <div>
          <img
            @click="goPath('/applyJoinUs')"
            :src="$t('contactUsData.wapImg1')"
            alt=""
            class="rela_img"
          />
        </div>
      </div>
      <!-- 达人入驻 2023-06-13隐藏-->
      <!-- <wap-from-world
        id="4"
        :title="$t('WapIndexIntr3.title')"
        :details="$t('WapIndexIntr3.details')"
      />
      <wap-start-com :pic="$t('WapIndexIntr3.pic')" /> -->
      <!-- 公司资讯 -->
      <!-- <wap-from-world id="5" :title="$t('WapIndexIntr4.title')" /> -->
      <!-- <wap-company-intr :pic="$t('WapIndexIntr4.pic')" /> -->
      <!-- 合作品牌 -->
      <wap-from-world id="6" :title="$t('WapIndexIntr5.title')" />
      <wap-relation :pic="$t('WapIndexIntr5.pic')" />
      <!-- 底部 -->
      <wap-bottom />
    </div>
  </div>
</template>
<script>
// 以下为PC端组件
// 引入公共组件
import TopMenuBar from "@/views/publicComponents/TopMenuBar";
import BottomDet from "@/views/publicComponents/BottomDet";
import WapBottom from "@/views/publicComponents/WapBottom";
import BarWap from "@/views/publicComponents/BarWap";
// import BottomImg from "@/views/publicComponents/BottomImg";

// 引入子组件
import PcTopSweiper from "./components/PcTopSweiper";
// import PcGroupIntroduction from "./components/PcGroupIntroduction";
const FromWorld = () =>
  import("./components/FromWorld").then((m) => m.default || m);
const CompantyCon = () =>
  import("./components/CompantyCon").then((m) => m.default || m);
// const StareCom = () =>
//   import("./components/StareCom").then(m => m.default || m);
// const CompanyIntr = () =>
//   import("./components/CompanyIntr").then(m => m.default || m);
const GropIntr = () =>
  import("./components/GropIntr").then((m) => m.default || m);
const WapGropIntr = () =>
  import("./components/WapGropIntr").then((m) => m.default || m);
const WapTopSweiper = () =>
  import("./components/WapTopSweiper").then((m) => m.default || m);
const WapFromWorld = () =>
  import("./components/WapFromWorld").then((m) => m.default || m);
const WapCompantyCon = () =>
  import("./components/WapCompantyCon").then((m) => m.default || m);
// const WapCompanyIntr = () =>
//   import("./components/WapCompanyIntr").then(m => m.default || m);
// const WapStartCom = () =>
//   import("./components/WapStartCom").then(m => m.default || m);
const WapRelation = () =>
  import("./components/WapRelation").then((m) => m.default || m);

export default {
  name: "Index",
  components: {
    TopMenuBar,
    BottomDet,
    // BottomImg,
    PcTopSweiper,
    // PcGroupIntroduction,
    FromWorld,
    CompantyCon,
    // StareCom,
    // CompanyIntr,
    WapTopSweiper,
    WapFromWorld,
    WapCompantyCon,
    // WapCompanyIntr,
    // WapStartCom,
    WapRelation,
    WapBottom,
    BarWap,
    GropIntr,
    WapGropIntr,
  },
  data() {
    return {
      bgStyle: {
        backgroundColor: "rgba(255,255,255," + 0 + ")",
      },
      opacityStyle: {
        opacity: 0,
      },
      curScroll: 0,
    };
  },
  methods: {
    goPath(path) {
      console.log(111);
      if (
        (!localStorage.getItem("AZG_TOKEN") ||
          localStorage.getItem("AZG_TOKEN") === null) &&
        path !== "/companyintr"
      ) {
        this.$router.push("/login");
        return;
      }
      this.$router.push(path);
    },
    jump() {
      this.$router.push("/");
    },
    openMenus() {
      this.showMenu = !this.showMenu;
    },
    curIndex(index) {
      this.curScroll = index;
    },
  },
};
</script>
<style lang="less" scoped>
@media only screen and (max-width: 1200px) {
  .bar {
    width: 64%;
    margin: 0 auto;
  }
  .bar_list {
    display: flex;
    justify-content: space-around;
    margin-left: calc(100vw * 80 / 1520);
    width: calc(64vw - 100vw * 80 / 1520 - 46px);
  }
}

.pop_img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px calc(100vw * 7.5 / 192) 0 calc(100vw * 10.5 / 192);
  gap: calc(100vw * 3 / 192);
  div {
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .top {
    margin-top: 86px;
    position: relative;
    width: 100%;
    height: calc(100vh - 86px);

    .top_bg_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .top_kouhao {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-size: 24px;
      color: white;
      font-weight: 700;
    }
  }

  .content {
    padding-top: 0;
    // border-top: 4px dashed #bf9264;
  }

  .bar_phone {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    height: 86px;
    z-index: 199;

    .bar_logo {
      width: 46px;
      height: 54px;
      margin-top: 16px;
      margin-left: 16px;
      position: relative;
      float: left;
    }

    .bar_menu {
      width: 24px;
      height: 24px;
      float: right;
      margin-top: 31px;
      margin-right: 31px;
    }
  }

  .menus {
    position: fixed;
    top: 86px;
    left: 0;
    right: 0;
    z-index: 199;
    background: rgba(255, 255, 255, 0.95);
    padding: 12px 0;

    .menu_item {
      padding: 8px 16px;
      text-align: left;

      a:link {
        text-decoration: none;
        color: #c4c4c4;
      }
      a:visited {
        text-decoration: none;
        color: #c4c4c4;
      }
      a:hover {
        text-decoration: none;
        color: #c4c4c4;
      }
      a:active {
        text-decoration: none;
        font-size: 14px;
        color: #c4c4c4;
      }
    }
  }
  .pop_img {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // margin: 50px calc(100vw * 7.5 / 192) 0 calc(100vw * 10.5 / 192);
    margin: 0;
    gap: calc(100vw * 10 / 375);
    div {
      display: flex;
    }
  }
}
.none {
  // height: 400px;
  // background: #c4c4c4;
  // margin-top: calc(100vw * 5.6 / 37.5);
  margin-top: 60px;
}
.rela_img {
  width: 100%;
  display: block;
}
.can_click {
  cursor: pointer;
}
.some_com {
  margin: calc(100vw * 5 / 192) calc(100vw * 32.4 / 192) 0;
  img {
    display: block;
    width: 100%;
  }
}
</style>
